import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import { SidebarContainer } from '../../sidebar/main/containers/SidebarContainer';
import { TableContainer } from '../../table/main/containers/TableContainer';
import { CreateUserDialogContainer } from '../../sidebar/tabs/user/containers/CreateUserDialogContainer';
import { FormattedMessage } from 'react-intl';
import { RouteUpdater } from '../../../router/RouteUpdater';
import { DriversPropertiesFromState } from '../containers/DriversContainer';
import { DeleteUserDialogContainer } from '../../../dialogs/containers/DeleteUserDialogContainer';

type DriversProps = DriversPropertiesFromState;

export const Drivers = (props: DriversProps) => {
    const { header, selectedDriver } = props;

    const maintenanceMode = false;

    const banner = maintenanceMode ? (
        <ApplicationLayoutBodyBanner>
            <FormattedMessage id={'intl-msg:maintenanceMode'} />
        </ApplicationLayoutBodyBanner>
    ) : null;
    return (
        <ApplicationLayout id="ApplicationLayout">
            {header}
            <ApplicationLayout.Sidebar className={'right'}>
                {selectedDriver && <SidebarContainer />}
            </ApplicationLayout.Sidebar>
            <CreateUserDialogContainer />
            <DeleteUserDialogContainer />
            <ApplicationLayout.Body className={'DriverAdmin responsive'} banner={banner}>
                <NotificationsContainer id={'NotificationsContainer'} />
                <RouteUpdater />
                <TableContainer />
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
};
