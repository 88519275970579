import Joi from 'joi';
import { clone, equals } from 'ramda';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import { DriverStatusInRoute } from './router/types';
import { SortByField } from '../../types';

export const FLEET_DRIVER_GROUP = 'Fleet driver';

export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';
export const sortDirection = [SORT_ASC, SORT_DESC];

export const INTL_FIELD_FIRST_NAME = 'intl-msg:firstName';
export const INTL_FIELD_LAST_NAME = 'intl-msg:lastName';
export const INTL_FIELD_CARD = 'intl-msg:card';
export const INTL_FIELD_ACTIVE_CARD = 'intl-msg:status.active';
export const INTL_FIELD_STATUS = 'intl-msg:status';
export const INTL_FIELD_EMAIL = 'intl-msg:email';
export const INTL_FIELD_PHONE = 'intl-msg:phoneNumber';
export const INTL_FIELD_TAG = 'intl-msg:tag';
export const INTL_FIELD_USER = 'intl-msg:user';

export const INTL_FIELD_DRIVER_STATUS_ACTIVE = 'intl-msg:status.active';
export const INTL_FIELD_DRIVER_STATUS_ARCHIVED = 'intl-msg:status.archived';

export const sortableFields = Object.values(SortByField);

export const driversSortFieldNameSchema = Joi.string().valid(...sortableFields);

export const LIST_MODE_LIST = TableViewToggles.VIEW_TYPE_SINGLE_CARD;
export const LIST_MODE_CARDS = TableViewToggles.VIEW_TYPE_MULTI_CARDS;
export const LIST_MODE_TABLE = TableViewToggles.VIEW_TYPE_TABLE;

export const listMode = {
    isList: equals(LIST_MODE_LIST),
    isSplit: equals(LIST_MODE_CARDS),
    isTable: equals(LIST_MODE_TABLE),
};

export const driverIdSchema = Joi.string().uuid();
export const accountIdSchema = Joi.string();

export const PROP_DRIVER_ID = 'driverId';
export const PROP_ACCOUNT_ID = 'accountId';
export const PROP_FIRST_NAME = 'firstName';
export const PROP_LAST_NAME = 'lastName';
export const PROP_STATUS = 'status';
export const PROP_ACTIVE_CARD_NUMBER = 'activeCardNumber';
export const PROP_OTHER_CARD_NUMBERS = 'otherCardNumbers';
export const PROP_EMAIL = 'email';
export const PROP_PHONE_NUMBER = 'phoneNumber';

export const driverFirstNameSchema = Joi.string().max(255).allow('').allow(null);
export const driverFirstNameUpdateSchema = Joi.string().max(255).allow('');
export const userFirstNameSchema = Joi.string().max(40).required();
export const driverLastNameSchema = Joi.string().trim().max(255).allow('').allow(null);
export const userLastNameSchema = Joi.string().trim().max(40).required();
export const driverLastNameUpdateSchema = Joi.string().trim().max(255);
export const driverEmailSchema = Joi.string()
    .email({ tlds: { allow: false } })
    .max(73)
    .allow('')
    .allow(null);
export const driverPhoneNumberSchema = Joi.string()
    .trim()
    .regex(/\+[1-9][0-9]{5,13}/)
    .max(15)
    .allow('')
    .allow(null);
export const driverStatusInRouteSchema = Joi.string()
    .valid(DriverStatusInRoute.ACTIVE, DriverStatusInRoute.ARCHIVED, DriverStatusInRoute.ALL)
    .allow(null);
export const driverStatusFilterSchema = Joi.array().items(driverStatusInRouteSchema).min(0);
export const tagNameSchema = Joi.string().max(255);

export const driverStatusSchema = Joi.string()
    .valid(DriverStatusInRoute.ACTIVE, DriverStatusInRoute.ARCHIVED)
    .allow(null);
export const driverSchema = Joi.object().keys({
    driverId: driverIdSchema.required(),
    accountId: accountIdSchema.required(),
    firstName: driverFirstNameSchema,
    lastName: driverLastNameSchema,
    language: Joi.string().allow('').allow(null),
    phoneNumber: driverPhoneNumberSchema,
    email: driverEmailSchema,
    annotation: Joi.string().allow('').allow(null),
    status: driverStatusSchema,
    driverGroupNames: Joi.array(),
});

const joiOptions = {
    allowUnknown: true,
    convert: false,
    noDefaults: true,
};

export type JoiSchema = Joi.StringSchema | Joi.ArraySchema | Joi.ObjectSchema;

export const configureValidate = (console: Console) => (schema: JoiSchema, value: any) => {
    if (!schema) {
        throw new Error('Please supply a "joi" schema to "validate".');
    }

    if (typeof value === 'undefined') {
        const error = new Error(`Value ${value} must be defined.`);
        console.warn(error);
        return {
            error,
            value,
        };
    }

    const result = schema.validate(clone(value), joiOptions);
    if (result.error) {
        console.warn(result.error);
    }
    return result;
};

export const validate = configureValidate(window.console);
