import { memo } from 'react';
import classNames from 'classnames';
import { CharCount } from '../../../dialogs/components/CharCount';
import { FormInputGroupProperties } from '../tabs/types/driverInfoFormTypes';

export const FormInputGroup = memo((props: FormInputGroupProperties) => {
    const {
        fieldName,
        fieldLabel,
        fieldError,
        icon,
        value,
        onChange,
        readOnly,
        dataAttribute,
        type,
        requiredLength,
        maxLength,
    } = props;

    const isNotLongEnough = requiredLength && value ? value.length !== requiredLength : false;
    const showReadBoundary = fieldError || isNotLongEnough;

    return (
        <div className={classNames('form-group', showReadBoundary && 'has-feedback has-error')}>
            <label className={'control-label'} style={{ width: '100%' }} htmlFor={fieldName}>
                {fieldLabel}
            </label>
            <div className={'input-group'}>
                {icon && (
                    <span className={'input-group-addon'}>
                        <span className={icon} />
                    </span>
                )}
                {requiredLength && <CharCount value={value} requiredCharacters={requiredLength} />}
                <input
                    className={'form-control'}
                    id={fieldName}
                    name={fieldName}
                    onChange={onChange}
                    type={type}
                    value={value}
                    readOnly={readOnly}
                    maxLength={maxLength}
                    data-testid={dataAttribute}
                />
            </div>
            {fieldError && (
                <span className={'help-block'}>
                    <span>{fieldError}</span>
                </span>
            )}
        </div>
    );
});
