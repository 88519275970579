import { FormattedMessage } from 'react-intl';

export enum DriverMultiSelectChangeStatusValue {
    ACTIVATE = 'activate',
    ARCHIVE = 'archive',
}

interface DriverMultiSelectChangeStatusDialogBodyOwnProps {
    selectedDriversCount: number;
    newStatus: DriverMultiSelectChangeStatusValue;
}

export const DriverMultiSelectChangeStatusDialogBody = (props: DriverMultiSelectChangeStatusDialogBodyOwnProps) => {
    return (
        <>
            <div data-testid={`driver-multi-select-${props.newStatus}-dialog-body`}>
                <FormattedMessage
                    id={`intl-msg.multiSelect.${props.newStatus}.content`}
                    values={{ amount: props.selectedDriversCount }}
                />
            </div>
        </>
    );
};
