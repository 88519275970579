import { api } from '../../api';
import {
    showDeleteDriverSuccessNotification,
    showErrorNotification,
    showSuccessNotification,
} from '../notifications/notifications';
import { reportErrorToSentry } from '../../../configuration/setup/sentry';
import { Driver, User } from '../../../types';
import { ThunkDispatch } from 'redux-thunk';
import { ErrorOrigin, ErrorResponse, getErrorCode } from '../../apiUtils';
import { fetchDrivers } from './fetchThunks';
import { deleteDriverSuccess, deleteUserSuccess } from '../drivers/sidebar/sidebarSlice';

export const deleteUser = (userToDelete: User) => (dispatch: ThunkDispatch<any, any, any>) => {
    return api
        .deleteUser(userToDelete)
        .then((response: Response) => {
            showSuccessNotification('intl-msg:users.delete.success');
            dispatch(deleteUserSuccess());
            return response;
        })
        .catch((error: Error) => {
            handleDeleteUserFailed(error, userToDelete);
        });
};

export const handleDeleteUserFailed = (error: Error, userToDelete: User) => {
    if (error.message.startsWith('401') || error.message.startsWith('403')) {
        showErrorNotification('intl-msg:error.user.delete.unauthorized', true);
    } else {
        reportErrorToSentry(
            new Error(
                `Could not delete user ${JSON.stringify(userToDelete)} due to the following error: ${JSON.stringify(
                    error
                )}`
            )
        );
        showErrorNotification('intl-msg:error.user.delete.internal', true);
    }
};

export const deleteDriver = (driverToDelete: Driver) => (dispatch: ThunkDispatch<any, any, any>) => {
    return api
        .deleteDriver(driverToDelete)
        .then((response: Response) => {
            showDeleteDriverSuccessNotification(driverToDelete);

            dispatch(deleteDriverSuccess());
            dispatch(fetchDrivers());

            return response;
        })
        .catch((errorResponse: ErrorResponse) => {
            showErrorNotification(getErrorCode(errorResponse, ErrorOrigin.DRIVER_ADMINISTRATION));
        });
};
