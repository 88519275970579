import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

interface CreateNewUserStepProperties {
    onCreateUserWithEmail: () => void;
    onCreateUserWithPhone: () => void;
}

export const CreateNewUserStep = memo((props: CreateNewUserStepProperties) => {
    return (
        <div className={'padding-15'}>
            <div className={'padding-15 text-center'}>
                <div className={'text-size-18 line-height-125rel'}>
                    <FormattedMessage id={'intl-msg:user.login.method'} />
                </div>
                <div className={'text-size-12 line-height-125rel'}>
                    <FormattedMessage id={'intl-msg:user.login.selection'} />
                </div>
            </div>
            <div className={'display-flex flex-wrap justify-content-center'}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                    className={'btn btn-primary btn-action text-size-20 margin-10'}
                    data-testid={'addUserByEmailBtn'}
                    onClick={props.onCreateUserWithEmail}
                >
                    <span className={'rioglyph rioglyph-envelope'} />
                    <FormattedMessage id={'intl-msg:email'} />
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                    className={'btn btn-primary btn-action text-size-20 margin-10'}
                    data-testid={'addUserByPhoneBtn'}
                    onClick={props.onCreateUserWithPhone}
                >
                    <span className={'rioglyph rioglyph-phone'} />
                    <FormattedMessage id={'intl-msg:phoneNumber'} />
                </a>
            </div>
        </div>
    );
});

CreateNewUserStep.displayName = 'CreateNewUserStep';
