export interface CharCountProps {
    value?: string;
    requiredCharacters: number;
}

export const CharCount = (props: CharCountProps) => {
    const currentCharacters = props.value?.length ?? 0;

    const renderDiv = currentCharacters !== props.requiredCharacters && currentCharacters > 0;
    return renderDiv ? (
        <div
            className={
                'text-color-danger position-absolute right-10 top-50pct transform translate-y-50pct text-italic z-index-3 pointer-events-none'
            }
        >
            {`${currentCharacters} / ${props.requiredCharacters}`}
        </div>
    ) : (
        <></>
    );
};
