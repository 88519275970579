import { clone, mergeDeepRight } from 'ramda';

export const authorizeFetch = (storage, fetch) => (url, options) =>
    fetch(
        url,
        mergeDeepRight(clone(options), {
            headers: {
                Authorization: `Bearer ${storage.getAccessToken()}`,
            },
        })
    );
