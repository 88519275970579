import { FormattedMessage } from 'react-intl';
import EmptyState from '@rio-cloud/rio-uikit/EmptyState';

export const Empty = () => (
    <div className={'display-flex justify-content-center'}>
        <EmptyState
            headline={<FormattedMessage id={'intl-msg:no.drivers'} />}
            message={<FormattedMessage id={'intl-msg:no.drivers.message'} />}
        />
    </div>
);
