import React, { memo } from 'react';

interface LinkProperties {
    targetUri: string;
    text: string;
    onClick: (event: React.MouseEvent) => void;
}

export const Link = memo((props: LinkProperties) => {
    const { targetUri, text, onClick } = props;
    return (
        <a onClick={onClick} href={targetUri} target={'_blank'} rel={'noopener noreferrer'}>
            {text}
        </a>
    );
});
