import { User } from '../../../../types';
import { getShowDeleteUserDialog, getUsersToDelete } from '../../../Drivers.selectors';
import { ThunkDispatch } from 'redux-thunk';
import { deleteUser } from '../../thunks/deleteThunks';
import { connect } from 'react-redux';
import { DeleteUserDialog } from '../components/DeleteUserDialog';
import { fetchUsers } from '../../thunks/fetchThunks';
import { RootState } from '../../../../configuration/setup/store';
import { setUsersToDelete, toggleDeleteUserDialog } from '../../drivers/sidebar/tabs/user/userSlice';

export interface DeleteUserDialogPropsFromState {
    usersToDelete: User[];
    showUsersDeleteDialog: boolean;
}

export interface DeleteUserDialogPropsFromDispatch {
    deleteUser: (user: User) => void;
    toggleDeleteUserDialog: (show: boolean) => void;
    setUsersToDelete: (users: User[]) => void;
    fetchUsers: () => void;
}

export const mapStateToProps = (state: RootState): DeleteUserDialogPropsFromState => ({
    usersToDelete: getUsersToDelete(state),
    showUsersDeleteDialog: getShowDeleteUserDialog(state),
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DeleteUserDialogPropsFromDispatch => ({
    deleteUser: (user: User) => dispatch(deleteUser(user)),
    toggleDeleteUserDialog: (show: boolean) => dispatch(toggleDeleteUserDialog(show)),
    setUsersToDelete: (users: User[]) => dispatch(setUsersToDelete(users)),
    fetchUsers: () => dispatch(fetchUsers()),
});

export const DeleteUserDialogContainer = connect(mapStateToProps, mapDispatchToProps)(DeleteUserDialog);
