import { api } from '../../api';
import { showErrorNotification, showSuccessNotification } from '../notifications/notifications';
import { fetchDrivers, fetchUsers } from './fetchThunks';
import { Driver } from '../../../types';
import { ThunkDispatch } from 'redux-thunk';
import { ErrorOrigin, ErrorResponse, getErrorCode } from '../../apiUtils';
import { saveDriverFailed, saveDriverInProgress, saveDriverSuccess } from '../drivers/sidebar/sidebarSlice';

export const saveDriver = (driverToSave: Driver) => (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(saveDriverInProgress());

    return api
        .saveDriver(driverToSave)
        .then((response: Response) => {
            showSuccessNotification('intl-msg:save.success');
            dispatch(saveDriverSuccess(driverToSave));

            // fetch list of drivers again as the state may have changed
            dispatch(fetchUsers());
            dispatch(fetchDrivers());

            return response;
        })
        .catch((errorResponse: ErrorResponse) => {
            showErrorNotification(getErrorCode(errorResponse, ErrorOrigin.DRIVER_ADMINISTRATION));
            dispatch(saveDriverFailed());
        });
};

export const saveDrivers = (driversToSave: Driver[]) => (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(saveDriverInProgress());

    return Promise.all(
        driversToSave.map((driverToSave) => {
            return api
                .saveDriver(driverToSave)
                .then((response: Response) => {
                    showSuccessNotification('intl-msg:save.success');
                    dispatch(saveDriverSuccess(driverToSave));
                    return response;
                })
                .catch((errorResponse: ErrorResponse) => {
                    showErrorNotification(getErrorCode(errorResponse, ErrorOrigin.DRIVER_ADMINISTRATION));
                    dispatch(saveDriverFailed());
                });
        })
    ).then(() => {
        dispatch(fetchDrivers());
        dispatch(fetchUsers());
    });
};
