import Joi from 'joi';
import * as tokenHandling from '../configuration/tokenHandling/accessToken';
import { driverSchema, validate } from './app/schema';
import { getEndpoints } from './apiEndpoints';
import { config } from '../config';

const tokenStorage = tokenHandling.accessToken;
const fetch = window.fetch;

const validateDrivers = (drivers: any) => {
    validate(Joi.array().items(driverSchema), drivers);
    return drivers;
};
const validateSingleDriver = (driver: any) => {
    validate(driverSchema.required(), driver);
    return driver;
};

const endpoints = getEndpoints();

export const api = {
    fetchAllDrivers: endpoints.fetchAllDriversEndpoint(
        fetch,
        tokenStorage,
        validateDrivers,
        config.backend.DRIVERADMIN_SERVICE
    ),
    fetchDriver: endpoints.fetchDriverEndpoint(
        fetch,
        tokenStorage,
        validateSingleDriver,
        config.backend.DRIVERADMIN_SERVICE
    ),
    saveDriver: endpoints.saveDriverEndpoint(fetch, tokenStorage, config.backend.DRIVERADMIN_SERVICE),
    addIdentificationToDriver: endpoints.addIdentificationEndpoint(
        fetch,
        tokenStorage,
        config.backend.DRIVERADMIN_SERVICE
    ),
    deleteIdentificationOfDriver: endpoints.deleteIdentificationEndpoint(
        fetch,
        tokenStorage,
        config.backend.DRIVERADMIN_SERVICE
    ),
    fetchAllUsersInAccount: endpoints.fetchAllUsersEndpoint(fetch, tokenStorage, config.backend.USERADMIN_SERVICE),
    inviteUser: endpoints.inviteUserEndpoint(fetch, tokenStorage, config.backend.USERADMIN_SERVICE),
    getGroups: endpoints.getGroupsEndpoint(fetch, tokenStorage, config.backend.USERADMIN_SERVICE),
    deleteUser: endpoints.deleteUserEndpoint(fetch, tokenStorage, config.backend.USERADMIN_SERVICE),
    deleteDriver: endpoints.deleteDriverEndpoint(fetch, tokenStorage, config.backend.DRIVERADMIN_SERVICE),
    fetchTags: endpoints.fetchTagsEndpoint(fetch, tokenStorage, config.backend.TAGS_SERVICE),
    createTag: endpoints.createTagsEndpoint(fetch, tokenStorage, config.backend.TAGS_SERVICE),
    tagDriver: endpoints.tagDriverEndpoint(fetch, tokenStorage, config.backend.DRIVERADMIN_SERVICE),
    untagDriver: endpoints.untagDriverEndpoint(fetch, tokenStorage, config.backend.DRIVERADMIN_SERVICE),
};
