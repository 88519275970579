import { connect } from 'react-redux';
import { getShowUserCreation, getUserCreationStep } from '../../../../../../Drivers.selectors';
import { inviteUserAndSaveDriver } from '../../../../../thunks/inviteThunks';
import { saveDriver } from '../../../../../thunks/saveDriverThunks';
import { getLocale } from '../../../../../../../configuration/lang/langSlice';
import { CreateDialogStepName, Driver, User } from '../../../../../../../types';
import { CreateUserDialog } from '../components/CreateUserDialog';
import { getSelectedDriverRtk } from '../../../sidebarSlice';
import { createUserGoToStep, createUserToggleDialog } from '../userSlice';
import { AppDispatch, RootState } from '../../../../../../../configuration/setup/store';

export interface CreateUserDialogPropertiesFromState {
    showDialog: boolean;
    currentStep: CreateDialogStepName;
    selectedDriver: Driver | null;
    preferredLocale: string | undefined;
}

export interface CreateUserDialogPropertiesFromDispatch {
    goToStep: (stepName: CreateDialogStepName) => void;
    closeCreateUserDialog: () => void;
    createUserAndSaveDriver: (user: User, selectedDriver: Driver) => void;
    saveSelectedDriver: (driver: Driver) => void;
}

const mapStateToProps = (state: RootState): CreateUserDialogPropertiesFromState => {
    return {
        showDialog: getShowUserCreation(state),
        currentStep: getUserCreationStep(state),
        selectedDriver: getSelectedDriverRtk(state),
        preferredLocale: getLocale(state),
    };
};

export const mapDispatchToProps = (dispatch: AppDispatch): CreateUserDialogPropertiesFromDispatch => ({
    goToStep: (stepName: CreateDialogStepName) => dispatch(createUserGoToStep(stepName)),
    closeCreateUserDialog: () => dispatch(createUserToggleDialog()),
    createUserAndSaveDriver: (user: User, selectedDriver: Driver) => {
        dispatch(createUserToggleDialog());
        dispatch(inviteUserAndSaveDriver(user, selectedDriver));
    },
    saveSelectedDriver: (driver: Driver) => {
        dispatch(createUserToggleDialog());
        dispatch(saveDriver(driver));
    },
});

export const CreateUserDialogContainer = connect(mapStateToProps, mapDispatchToProps)(CreateUserDialog);
