import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tenant } from '../../../../configuration/types';
import { mapTenantToTenantType, TenantType } from '../../tenantTypes';

export interface DeleteIdentificationsConfirmDialogProps {
    show: boolean;
    identificationsToBeDeleted: string[];
    handleConfirmDeleteIdentifications: () => void;
    handleCancelDeleteIdentifications: () => void;
    tenant: Tenant | undefined;
}

export const DeleteIdentificationsConfirmDialog = (props: DeleteIdentificationsConfirmDialogProps) => {
    const intl = useIntl();
    const identificationsList = (
        <ul>
            {props.identificationsToBeDeleted.map((identificationIds, index) => {
                return <li key={index}>{identificationIds}</li>;
            })}
        </ul>
    );

    const tenantType = mapTenantToTenantType(props.tenant);
    const getDialogText = (): { title: string; content: string } => {
        if (tenantType === TenantType.EU_TACHOGRAPH) {
            return {
                title: intl.formatMessage({ id: 'intl-msg:dialog.confirmDeleteIdentification.title.euDriverCard' }),
                content: intl.formatMessage({ id: 'intl-msg:dialog.confirmDeleteIdentification.content.euDriverCard' }),
            };
        } else if (tenantType === TenantType.COUNTRY_SPECIFIC) {
            return {
                title: intl.formatMessage({ id: 'intl-msg:dialog.confirmDeleteIdentification.title.driverLicense' }),
                content: intl.formatMessage({
                    id: 'intl-msg:dialog.confirmDeleteIdentification.content.driverLicense',
                }),
            };
        } else {
            return {
                title: '',
                content: '',
            };
        }
    };
    const dialogText = getDialogText();

    return (
        <ConfirmationDialog
            show={props.show}
            title={dialogText.title}
            content={
                <>
                    <span>{dialogText.content}</span>
                    {identificationsList}
                </>
            }
            onClickConfirm={props.handleConfirmDeleteIdentifications}
            onClickCancel={props.handleCancelDeleteIdentifications}
            cancelButtonText={<FormattedMessage id={'intl-msg:dialog.discard.keepEditing'} />}
            confirmButtonText={<FormattedMessage id={'intl-msg:save'} />}
            useOverflow={false}
        />
    );
};
