import CustomState from '@rio-cloud/rio-uikit/CustomState';
import TagManager from '@rio-cloud/rio-uikit/TagManager';
import intersection from 'lodash/intersection';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';
import { Tag } from '../../../../../types';
import {
    DriverMultiSelectAddDialogBodyPropertiesFromDispatch,
    DriverMultiSelectAddDialogBodyPropertiesFromState,
} from '../containers/DriverMultiSelectAddDialogBodyContainer';
import { TagManagerTag } from '@rio-cloud/rio-uikit/types';

interface DriverMultiSelectAddDialogBodyOwnProps {
    selectedDriverIds: string[];
}

export type DriverMultiSelectAddDialogBodyProperties = DriverMultiSelectAddDialogBodyPropertiesFromState &
    DriverMultiSelectAddDialogBodyPropertiesFromDispatch &
    DriverMultiSelectAddDialogBodyOwnProps &
    WrappedComponentProps;

export const DriverMultiSelectAddDialogBody = (props: DriverMultiSelectAddDialogBodyProperties) => {
    const { selectedDriverIds, allTagsInAccount, setTagsToAdd, setTagsToCreate, intl, drivers } = props;

    const getSelectedDrivers = () => {
        return drivers.filter((driver) => selectedDriverIds.includes(driver.driverId));
    };

    const getTagSuggestions = () => {
        const commonTagIdsOfSelectedDrivers = intersection(...getSelectedDrivers().map((driver) => driver.tags));
        return allTagsInAccount.filter((tag) => !commonTagIdsOfSelectedDrivers.includes(tag.id));
    };

    const onTagListChangeForAdding = (tagManagerTagProps: TagManagerTag[]) => {
        const tagsToAdd = allTagsInAccount.filter((tag) =>
            tagManagerTagProps
                .filter((it) => it.toAdd)
                .map((it) => it.label)
                .includes(tag.name)
        );
        setTagsToAdd(tagsToAdd);

        const labels = tagManagerTagProps.filter((prop) => prop.label !== undefined).map((it) => it.label as string);
        const tagsToCreate = labels
            .filter((label) => !allTagsInAccount.map((it) => it.name).includes(label))
            .map((label) => ({ name: label }));
        setTagsToCreate(tagsToCreate);
    };

    const getFormattedMessage = (messageKey: string) => {
        return intl.formatMessage({ id: messageKey });
    };

    return (
        <>
            <CustomState
                headline={<FormattedMessage id={'intl-msg:tag'} />}
                message={
                    <div data-cy="tag-list">
                        <div className={'margin-bottom-20'}>
                            <FormattedMessage id={'intl-msg:tag.customMessage'} />
                        </div>
                        <TagManager
                            tagList={[]}
                            tagSuggestions={getTagSuggestions().map((tag: Tag) => ({
                                label: tag.name,
                            }))}
                            onTagListChange={onTagListChangeForAdding}
                            placeholder={getFormattedMessage('intl-msg:tag.placeholder')}
                            customTagPlaceholder={getFormattedMessage('intl-msg:tag.customTagPlaceholder')}
                            dropdownSeparatorText={getFormattedMessage('intl-msg:tag.dropdownSeparatorText')}
                            noItemMessage={getFormattedMessage('intl-msg:tag.noItemMessage')}
                            useCustomTags={true}
                            showInput={true}
                        />
                    </div>
                }
                icons={[
                    {
                        name: 'rioglyph rioglyph-van',
                        className: 'text-size-200pct',
                    },
                    {
                        name: 'rioglyph rioglyph-truck',
                        className: 'text-size-300pct',
                    },
                    {
                        name: 'rioglyph rioglyph-trailer',
                        className: 'text-size-200pct',
                    },
                ]}
                outerClassName={'margin-bottom-15 bg-highlight-decent'}
                condensed={true}
            />
        </>
    );
};
