import { Driver, Tag, TagToCreate, User } from '../../../../../types';
import {
    getMultiSelectTagsToAdd,
    getMultiSelectTagsToCreate,
    getMultiSelectTagsToRemove,
    getShowDeleteUserDialog,
    getUsersToDelete,
} from '../../../../Drivers.selectors';
import { connect } from 'react-redux';
import { DriverMultiSelect } from '../components/DriverMultiSelect';
import { clearAllMultiSelectTags } from '../../sidebar/tabs/groups/groupSlice';
import { injectIntl } from 'react-intl';
import { addTagsToDrivers, removeTagsFromDrivers } from '../../../thunks/tagDriversThunks';
import { deleteDriver } from '../../../thunks/deleteThunks';
import { activateDrivers, archiveDrivers } from '../../../thunks/DriversStatusThunks';
import { ThunkDispatch } from 'redux-thunk';
import { getDriversRtk, getSelectedDriverIdRtk, getUsersRtk } from '../../../appSlice';
import { setUsersToDelete, toggleDeleteUserDialog } from '../../sidebar/tabs/user/userSlice';

export interface DriverMultiSelectPropertiesFromState {
    drivers: Driver[];
    tagsToCreate: TagToCreate[];
    tagsToAdd: Tag[];
    usersToDelete: User[];
    showDeleteUserDialog: boolean;
    tagsToRemove: Tag[];
    users: User[];
    currentDriverId: string | null;
}

export interface DriverMultiSelectPropertiesFromDispatch {
    clearMultiSelectTags: () => void;
    createTagsAndAddTagsToDrivers: (
        driverIds: string[],
        accountId: string,
        tagsToCreate: TagToCreate[],
        tagsToAdd: Tag[]
    ) => void;
    removeTagsFromDrivers: (drivers: Driver[], tagsToRemove: Tag[]) => void;
    archiveDrivers: (currentDriverId: string | null, drivers: Driver[], removeUsers: boolean) => void;
    activateDrivers: (currentDriverId: string | null, drivers: Driver[]) => void;
    toggleDeleteUserDialog: (show: boolean) => void;
    setUsersToDelete: (usersToDelete: User[]) => void;
    deleteDriver: (driver: Driver) => void;
}

const mapStateToProps = (state: any): DriverMultiSelectPropertiesFromState => ({
    drivers: getDriversRtk(state),
    tagsToCreate: getMultiSelectTagsToCreate(state),
    tagsToAdd: getMultiSelectTagsToAdd(state),
    tagsToRemove: getMultiSelectTagsToRemove(state),
    users: getUsersRtk(state),
    showDeleteUserDialog: getShowDeleteUserDialog(state),
    usersToDelete: getUsersToDelete(state),
    currentDriverId: getSelectedDriverIdRtk(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DriverMultiSelectPropertiesFromDispatch => ({
    clearMultiSelectTags() {
        dispatch(clearAllMultiSelectTags());
    },
    createTagsAndAddTagsToDrivers(
        driverIds: string[],
        accountId: string,
        tagsToCreate: TagToCreate[],
        tagsToAdd: Tag[]
    ) {
        dispatch(addTagsToDrivers(driverIds, accountId, tagsToCreate, tagsToAdd));
    },
    removeTagsFromDrivers(drivers: Driver[], tagsToRemove: Tag[]) {
        dispatch(removeTagsFromDrivers(drivers, tagsToRemove));
    },
    archiveDrivers(currentDriverId: string | null, drivers: Driver[], removeUsers: boolean) {
        dispatch(archiveDrivers(currentDriverId, drivers, removeUsers));
    },
    activateDrivers(currentDriverId: string | null, drivers: Driver[]) {
        dispatch(activateDrivers(currentDriverId, drivers));
    },
    setUsersToDelete(users) {
        dispatch(setUsersToDelete(users));
    },
    toggleDeleteUserDialog(show: boolean) {
        dispatch(toggleDeleteUserDialog(show));
    },
    deleteDriver: (driver: Driver) => dispatch(deleteDriver(driver)),
});

export const DriverMultiSelectContainer = injectIntl(connect(mapStateToProps, mapDispatchToProps)(DriverMultiSelect));
