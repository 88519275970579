import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';

interface DiscardDialogProps {
    onConfirmDiscard: () => void;
    onKeepEditing: () => void;
}

export const DiscardDialog = memo((props: DiscardDialogProps) => (
    <ConfirmationDialog
        show={true}
        bsSize={'sm'}
        title={<FormattedMessage id={'intl-msg:dialog.discard.discardChangesTitle'} />}
        content={<FormattedMessage id={'intl-msg:dialog.discard.reallyDiscardChanges'} />}
        cancelButtonText={<FormattedMessage id={'intl-msg:dialog.discard.keepEditing'} />}
        confirmButtonText={<FormattedMessage id={'intl-msg:dialog.discard.discardChanges'} />}
        onClickCancel={props.onKeepEditing}
        onClickConfirm={props.onConfirmDiscard}
    />
));
