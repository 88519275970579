import { connect } from 'react-redux';
import { Tenant } from '../../../../../../configuration/types';
import {
    Driver,
    DriverStatus,
    NewIdentification,
    SidebarContentId,
    Tag,
    TagToCreate,
    User,
} from '../../../../../../types';
import { getTagsToAdd, getTagsToCreate, getTagsToRemove } from '../../../../../Drivers.selectors';
import { deleteDriver } from '../../../../thunks/deleteThunks';
import { fetchDrivers } from '../../../../thunks/fetchThunks';
import { updateDriver } from '../../../../thunks/updateDriverThunks';
import { DriverSidebar } from '../components/DriverSidebar';
import {
    changeTab,
    confirmDiscardAndChangeTab,
    getContentIdRtk,
    getFormErrorsRtk,
    getNewIdentificationValueRtk,
    getNextTabRtk,
    getPendingIdentificationDeletionsRtk,
    getSelectedDriverRtk,
    getSelectedDriverWithoutEditsRtk,
    getShowUnsavedChangesRtk,
    isChangedRtk,
    isFetchDriverInProgressRtk,
    keepEditing,
    resetSidebar,
} from '../../sidebarSlice';
import { getDriverStatusesRtk, getUsersRtk, isInviteInProgressRtk } from '../../../../appSlice';
import { cancelSidebarThunk } from '../../../../../crossSliceThunks';
import { getTenant } from '../../../../../../configuration/login/loginSlice';
import { setUsersToDelete, toggleDeleteUserDialog } from '../../tabs/user/userSlice';
import { clearAllTags } from '../../tabs/groups/groupSlice';

export interface SidebarPropertiesFromState {
    selectedDriver: Driver | null;
    selectedDriverWithoutEdits: Driver | null;
    driverStatuses: DriverStatus[];
    users: User[];
    tagsToCreate: TagToCreate[];
    tagsToAdd: Tag[];
    tagsToRemove: Tag[];
    contentId: string;
    formErrors: { [p: string]: string | undefined };
    fetchDriverInProgress: boolean;
    inviteUserRequested: boolean;
    hasChanges: boolean;
    showUnsavedChangesDialog: boolean;
    newIdentification: NewIdentification | null;
    identificationIdsPendingForDeletion: Set<string>;
    nextTab: SidebarContentId | null;
    tenant: Tenant | undefined;
}

export interface SidebarPropertiesFromDispatch {
    handleCancel: () => void;
    handleKeepEditing: () => void;
    handleTabChange: (contentId: SidebarContentId) => void;
    handleResetSidebar: () => void;
    deleteDriver: (driver: Driver) => void;
    saveSelectedDriver: (
        driver: Driver,
        tagsToCreate: TagToCreate[],
        tagsToAdd: Tag[],
        tagsToRemove: Tag[],
        newIdentification: NewIdentification | null,
        identificationIdsPendingForDeletion: Set<string>
    ) => void;
    confirmDiscardAndChangeTab: () => void;
    toggleDeleteUserDialog: (show: boolean) => void;
    setUsersToDelete: (users: User[]) => void;
}

export const mapStateToProps = (state: any): SidebarPropertiesFromState => ({
    selectedDriver: getSelectedDriverRtk(state),
    selectedDriverWithoutEdits: getSelectedDriverWithoutEditsRtk(state),
    driverStatuses: getDriverStatusesRtk(state),
    users: getUsersRtk(state),
    tagsToCreate: getTagsToCreate(state),
    tagsToAdd: getTagsToAdd(state),
    tagsToRemove: getTagsToRemove(state),
    contentId: getContentIdRtk(state),
    formErrors: getFormErrorsRtk(state),
    hasChanges: isChangedRtk(state),
    fetchDriverInProgress: isFetchDriverInProgressRtk(state),
    inviteUserRequested: isInviteInProgressRtk(state),
    showUnsavedChangesDialog: getShowUnsavedChangesRtk(state),
    newIdentification: getNewIdentificationValueRtk(state),
    identificationIdsPendingForDeletion: new Set(getPendingIdentificationDeletionsRtk(state)),
    nextTab: getNextTabRtk(state),
    tenant: getTenant(state),
});

export const mapDispatchToProps = (dispatch: any): SidebarPropertiesFromDispatch => ({
    handleCancel: () => dispatch(cancelSidebarThunk()),
    handleKeepEditing: () => dispatch(keepEditing()),
    handleTabChange: (contentId: SidebarContentId) => dispatch(changeTab(contentId)),
    handleResetSidebar: () => dispatch(resetSidebar()),
    deleteDriver: (driver: Driver) => {
        dispatch(deleteDriver(driver));
        dispatch(fetchDrivers());
    },
    saveSelectedDriver: (
        driver: Driver,
        tagsToCreate: TagToCreate[],
        tagsToAdd: Tag[],
        tagsToRemove: Tag[],
        newIdentification: NewIdentification | null,
        identificationIdsPendingForDeletion: Set<string>
    ) => {
        dispatch(
            updateDriver(
                driver,
                tagsToCreate,
                tagsToAdd,
                tagsToRemove,
                newIdentification,
                identificationIdsPendingForDeletion
            )
        );
    },
    confirmDiscardAndChangeTab: () => {
        dispatch(confirmDiscardAndChangeTab());
        dispatch(clearAllTags());
    },
    toggleDeleteUserDialog: (show: boolean) => dispatch(toggleDeleteUserDialog(show)),
    setUsersToDelete: (users: User[]) => dispatch(setUsersToDelete(users)),
});

export const SidebarContainer = connect(mapStateToProps, mapDispatchToProps)(DriverSidebar);
