import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateDialogStepName, User } from '../../../../../../types';

export interface UserDialogState {
    userDialog: {
        showDialog: boolean;
        currentStep: CreateDialogStepName;
    };
    userDeletionDialog: {
        showDialog: boolean;
        usersToDelete: User[];
    };
}

const initialState: UserDialogState = {
    userDialog: {
        showDialog: false,
        currentStep: CreateDialogStepName.USER_MODE_SELECTION,
    },
    userDeletionDialog: {
        showDialog: false,
        usersToDelete: [],
    },
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        createUserGoToStep: (state, action: PayloadAction<CreateDialogStepName>) => {
            state.userDialog.currentStep = action.payload;
        },
        createUserToggleDialog: (state) => {
            state.userDialog.showDialog = !state.userDialog.showDialog;
            state.userDialog.currentStep = CreateDialogStepName.USER_MODE_SELECTION;
        },
        toggleDeleteUserDialog: (state, action: PayloadAction<boolean>) => {
            state.userDeletionDialog.showDialog = action.payload;
        },
        setUsersToDelete: (state, action: PayloadAction<User[]>) => {
            state.userDeletionDialog.usersToDelete = action.payload;
        },
    },
});

export const { createUserGoToStep, createUserToggleDialog, setUsersToDelete, toggleDeleteUserDialog } =
    userSlice.actions;

export default userSlice.reducer;
