import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState, Driver, DriverStatus, SortByField, SortDirection, User } from '../../types';
import { LIST_MODE_TABLE, PROP_ACTIVE_CARD_NUMBER, SORT_ASC, SORT_DESC } from './schema';
import { ascend, clone, descend, prop, propIs, sortWith } from 'ramda';
import { formatUserNameForDisplay } from './utils';
import { resetSidebar } from './drivers/sidebar/sidebarSlice';
import { RootState } from '../../configuration/setup/store';

// handle key "cardName" as the fieldname is just "card"
const mapSortKey = (sortKey: any) => (sortKey === SortByField.FIELD_CARD ? PROP_ACTIVE_CARD_NUMBER : sortKey);

const getLowerCaseDriverProp = (sortKey: any, users: User[]) => (driver: Driver) => {
    let propValue;
    if (sortKey === SortByField.FIELD_USER) {
        const user = users.find((userInternal) => userInternal.subject === driver.subject);
        propValue = formatUserNameForDisplay(user);
    } else {
        propValue = prop(sortKey, driver) || '';
    }
    return propIs(String, propValue) ? propValue.toLowerCase() : propValue;
};

const sortDrivers = (
    drivers: Driver[] = [],
    users: User[] = [],
    sortKey: any,
    sortDir: SortDirection = SortDirection.ASCENDING
) => {
    const sortDirFunc =
        sortDir === SortDirection.ASCENDING
            ? ascend(getLowerCaseDriverProp(sortKey, users))
            : descend(getLowerCaseDriverProp(sortKey, users));
    return sortWith([sortDirFunc])(drivers);
};

const determineSortDir = (oldSortBy: any, oldSortDir: any, sortBy: any) => {
    if (oldSortBy) {
        const invertedSortDir = oldSortDir === SORT_ASC ? SORT_DESC : SORT_ASC;
        return oldSortBy === sortBy ? invertedSortDir : oldSortDir;
    }
    return SORT_ASC;
};

export const initialState: AppState = {
    drivers: [],
    driverCreation: {
        inProgress: false,
        showCreationDialog: false,
    },
    driversListMode: LIST_MODE_TABLE,
    searchText: '',
    sortDir: SortDirection.ASCENDING,
    sortBy: SortByField.FIELD_LAST_NAME,
    driverStatuses: [DriverStatus.ACTIVE],
    fetchDriversRequested: true,
    hasFetchError: false,
    users: [],
    inviteUserRequested: false,
    selectedDriverId: null,
    sessionWarningAcknowledged: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        // TOGGLE_DRIVER_CREATION_DIALOG
        toggleDriverCreationDialog: (state, action: PayloadAction<any>) => {
            state.driverCreation.showCreationDialog = action.payload;
        },
        // DRIVER_CREATION_STARTED
        driverCreationStarted: (state) => {
            state.driverCreation.inProgress = true;
        },
        // DRIVER_CREATION_FINISHED
        driverCreationFinished: (state) => {
            state.driverCreation.inProgress = false;
            state.driverCreation.showCreationDialog = false;
        },
        // DRIVER_DESELECTED
        driverDeselected: (state) => {
            state.selectedDriverId = null;
        },
        // DRIVER_SEARCH_TEXT_CHANGED
        searchTextChanged: (state, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        },
        // SORT_CHANGED
        sortChanged: (state, action: PayloadAction<{ sortBy: string; sortDir?: SortDirection }>) => {
            const sort = action.payload;
            const sortKey = mapSortKey(sort.sortBy);
            const sortDir = sort.sortDir ? sort.sortDir : determineSortDir(state.sortBy, state.sortDir, sort.sortBy);

            state.drivers = sortDrivers(clone(state.drivers), state.users, sortKey, sortDir);
            state.sortBy = sort.sortBy;
            state.sortDir = sortDir;
        },
        // DRIVER_STATUSES_CHANGED
        driverStatusesChanged: (state, action: PayloadAction<any>) => {
            state.driverStatuses = action.payload;
        },
        // DRIVERS_FETCH_REQUESTED
        fetchDriversRequested: (state) => {
            state.fetchDriversRequested = true;
        },
        // DRIVERS_FETCH_FAILED
        fetchDriversFailed: (state) => {
            state.fetchDriversRequested = false;
            state.hasFetchError = false;
        },
        // DRIVERS_FETCHED
        driversFetched: (state, action: PayloadAction<any>) => {
            const fetchedDriverList = action.payload || [];
            state.fetchDriversRequested = false;
            state.hasFetchError = false;
            state.drivers = sortDrivers(fetchedDriverList, state.users, mapSortKey(state.sortBy), state.sortDir);
        },
        // DRIVERS_LIST_MODE_CHANGED
        driversListModeChanged: (state, action: PayloadAction<any>) => {
            state.driversListMode = action.payload;
        },
        // SESSION_WARNING_DIALOG_CLOSED
        hideSessionWarning: (state) => {
            state.sessionWarningAcknowledged = true;
        },
        // USERS_FETCHED
        usersFetched: (state, action: PayloadAction<any>) => {
            const users = action.payload || [];
            if (state.sortBy === SortByField.FIELD_USER) {
                state.users = users;
                state.drivers = sortDrivers(state.drivers, users, mapSortKey(state.sortBy), state.sortDir);
            } else {
                state.users = users;
            }
        },
        // INVITE_USER_FAILED
        inviteUserFailed: (state) => {
            state.inviteUserRequested = true;
        },
        // INVITE_USER_SUCCESS
        inviteUserSuccess: (state) => {
            state.inviteUserRequested = false;
        },
        // INVITE_USER_REQUESTED
        inviteUserRequested: (state) => {
            state.inviteUserRequested = true;
        },

        // ----------------------------------------
        // FROM CROSS SLICE REDUCER
        // ----------------------------------------
        // DRIVER_SELECTED
        driverSelected: (state, action: PayloadAction<string | null>) => {
            state.selectedDriverId = action.payload;
        },
    },
    extraReducers: (builder) => {
        // RESET_SIDEBAR
        builder.addCase(resetSidebar, (state) => {
            state.selectedDriverId = null;
        });
        builder.addDefaultCase(() => {});
    },
});

export const {
    toggleDriverCreationDialog,
    driverCreationFinished,
    driverCreationStarted,
    searchTextChanged,
    driversFetched,
    fetchDriversFailed,
    fetchDriversRequested,
    driverStatusesChanged,
    driversListModeChanged,
    hideSessionWarning,
    inviteUserFailed,
    inviteUserSuccess,
    inviteUserRequested,
    usersFetched,
    sortChanged,
    driverSelected,
    driverDeselected,
} = appSlice.actions;

// SELECTORS
export const getDriversRtk = (state: RootState): Driver[] => state.app.drivers;
export const getDriverCreationInProgressRtk = (state: RootState) => state.app.driverCreation.inProgress;
export const getShowDriverCreationDialogRtk = (state: RootState) => state.app.driverCreation.showCreationDialog;
export const getDriverStatusesRtk = (state: RootState): DriverStatus[] => state.app.driverStatuses;
export const getDriversListModeRtk = (state: RootState) => state.app.driversListMode;
export const hasFetchDriversRequestedRtk = (state: RootState) => state.app.fetchDriversRequested;
export const hasFetchErrorRtk = (state: RootState) => state.app.hasFetchError;
export const getSearchTextRtk = (state: RootState) => state.app.searchText;
export const getSortByRtk = (state: RootState) => state.app.sortBy;
export const getSortDirRtk = (state: RootState) => state.app.sortDir;
export const getUsersRtk = (state: RootState): User[] => state.app.users;
export const isInviteInProgressRtk = (state: RootState) => state.app.inviteUserRequested;
export const getSelectedDriverIdRtk = (state: RootState) => state.app.selectedDriverId;
export const getSessionWarningAcknowledgedRtk = (state: RootState) => state.app.sessionWarningAcknowledged;

export default appSlice.reducer;
