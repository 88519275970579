import { DialogState, DriverStatus, SidebarContentId, SortDirection } from '../../../types';
import { DriverStatusInRoute, LocationChangePayload, RouteState } from './types';
import * as qs from 'qs';
import { PARSE_OPTIONS, STRINGIFY_OPTIONS } from './qsUtil';
import { enumFromEnumValue } from './enumUtils';
import Joi from 'joi';
import { RootState } from '../../../configuration/setup/store';

const DRIVERS_PATH = '/drivers';

export const parseRoute = (payload: LocationChangePayload): RouteState => {
    const { search: locationSearch, pathname: locationPathname } = payload.location;
    const { search, status, sortBy, sortDir, tab, createDriverModal } = qs.parse(
        locationSearch.replace('?', ''),
        PARSE_OPTIONS
    );
    return {
        driverId: getDriverIdFragmentFromPath(locationPathname),
        search: decodeUriComponentIfPresent(search),
        sortBy: decodeUriComponentIfPresent(sortBy),
        sortDir: enumFromEnumValue(SortDirection, decodeUriComponentIfPresent(sortDir)),
        status: enumFromEnumValue(DriverStatusInRoute, decodeUriComponentIfPresent(status)),
        tab: enumFromEnumValue(SidebarContentId, decodeUriComponentIfPresent(tab)),
        createDriverModal: enumFromEnumValue(DialogState, decodeUriComponentIfPresent(createDriverModal)),
    };
};

const getDriverIdFragmentFromPath = (path: string) => {
    const decodedPath = decodeURIComponent(path);
    const pathWithoutBase = decodedPath.replace(`${DRIVERS_PATH}`, '');
    const driverId = pathWithoutBase.startsWith('/') ? pathWithoutBase.replace('/', '') : pathWithoutBase;
    const validationResult = Joi.string().uuid().validate(driverId);
    if (validationResult.error) {
        return undefined;
    }
    return driverId ? driverId : undefined;
};

const decodeUriComponentIfPresent = (uriComponent: any): string | undefined => {
    return uriComponent && decodeURIComponent(uriComponent as string);
};

export const prepareState = (parsedUrlState: RouteState, state: RootState): RootState => {
    const newState: RootState = {
        ...state,
    };
    if (parsedUrlState.driverId !== undefined) {
        newState.app.selectedDriverId = parsedUrlState.driverId;
    }
    if (parsedUrlState.search !== undefined) {
        newState.app.searchText = parsedUrlState.search;
    }
    if (parsedUrlState.sortBy !== undefined) {
        newState.app.sortBy = parsedUrlState.sortBy;
    }
    if (parsedUrlState.sortDir !== undefined) {
        newState.app.sortDir = parsedUrlState.sortDir;
    }
    if (parsedUrlState.status !== undefined) {
        newState.app.driverStatuses = deduceDriverStatusesFrom(parsedUrlState.status);
    }
    if (parsedUrlState.tab !== undefined) {
        newState.sidebar.contentId = parsedUrlState.tab;
        newState.sidebar.pendingTabChangeNextTab = parsedUrlState.tab;
    }
    return newState;
};

export const getDriversUriForDeepLink = (state: RootState) => {
    const driverId = state.app.selectedDriverId;
    const driverFragment = driverId ? `/${driverId}` : '';
    const queryParams = qs.stringify(
        {
            search: state.app.searchText || undefined,
            status: state.app.driverStatuses && deduceDriverStatusInRouteFrom(new Set(state.app.driverStatuses)),
            sortBy: state.app.sortBy,
            sortDir: state.app.sortDir,
            tab: state.sidebar?.contentId,
        },
        STRINGIFY_OPTIONS
    );
    const searchFragment = queryParams && `?${queryParams}`;
    return encodeURI(`${DRIVERS_PATH}${driverFragment}`) + searchFragment;
};

export const deduceDriverStatusesFrom = (driverStatusInRoute: DriverStatusInRoute): DriverStatus[] => {
    switch (driverStatusInRoute) {
        case DriverStatusInRoute.ACTIVE:
            return [DriverStatus.ACTIVE];
        case DriverStatusInRoute.ARCHIVED:
            return [DriverStatus.ARCHIVED];
        case DriverStatusInRoute.ALL:
            return [DriverStatus.ACTIVE, DriverStatus.ARCHIVED];
    }
};

const deduceDriverStatusInRouteFrom = (driverStatuses: Set<DriverStatus>): DriverStatusInRoute | undefined => {
    let urlParamDriverStatus;
    switch (driverStatuses.size) {
        case 0:
            urlParamDriverStatus = undefined;
            break;
        case 1:
            urlParamDriverStatus = driverStatuses.values().next().value as DriverStatusInRoute;
            break;
        case 2:
            urlParamDriverStatus = DriverStatusInRoute.ALL;
            break;
        default:
            throw new Error(`Invalid number of checked driverStatuses. ${driverStatuses}`);
    }
    return urlParamDriverStatus;
};
