import { memo } from 'react';
import { FormInputGroup } from '../../../commonElements/FormInputGroup';
import {
    INTL_FIELD_EMAIL,
    INTL_FIELD_FIRST_NAME,
    INTL_FIELD_LAST_NAME,
    PROP_EMAIL,
    PROP_FIRST_NAME,
    PROP_LAST_NAME,
} from '../../../../../schema';
import { FormattedMessage } from 'react-intl';
import { SortByField, User } from '../../../../../../../types';

interface CreateNewUserByEmailStepProperties {
    user: User;
    formErrors: { [key: string]: string | undefined } | undefined;
    handleFirstNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleLastNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const getMessageById = (id: string) => id && <FormattedMessage id={id} />;

export const CreateNewUserByEmailStep = memo((props: CreateNewUserByEmailStepProperties) => {
    return (
        <div>
            <div className={'modal-body'}>
                <FormInputGroup
                    fieldError={
                        props.formErrors && props.formErrors[PROP_FIRST_NAME]
                            ? getMessageById(props.formErrors[PROP_FIRST_NAME]!)
                            : ''
                    }
                    fieldLabel={<FormattedMessage id={INTL_FIELD_FIRST_NAME} />}
                    fieldName={SortByField.FIELD_FIRST_NAME}
                    onChange={props.handleFirstNameChange}
                    readOnly={false}
                    value={props.user.firstName}
                    type={'text'}
                />
                <FormInputGroup
                    fieldError={
                        props.formErrors && props.formErrors[PROP_LAST_NAME]
                            ? getMessageById(props.formErrors[PROP_LAST_NAME]!)
                            : ''
                    }
                    fieldLabel={<FormattedMessage id={INTL_FIELD_LAST_NAME} />}
                    fieldName={SortByField.FIELD_LAST_NAME}
                    onChange={props.handleLastNameChange}
                    readOnly={false}
                    value={props.user.lastName}
                    type={'text'}
                />
                <FormInputGroup
                    fieldError={
                        props.formErrors && props.formErrors[PROP_EMAIL]
                            ? getMessageById(props.formErrors[PROP_EMAIL]!)
                            : ''
                    }
                    fieldLabel={<FormattedMessage id={INTL_FIELD_EMAIL} />}
                    fieldName={SortByField.FIELD_EMAIL}
                    onChange={props.handleEmailChange}
                    readOnly={false}
                    value={props.user.email}
                    icon={'rioglyph rioglyph-envelope'}
                    type={'email'}
                />
            </div>
        </div>
    );
});

CreateNewUserByEmailStep.displayName = 'CreateNewUserByEmailStep';
