import { IntlShape } from 'react-intl';
import { Tenant } from '../../configuration/types';

export enum TenantType {
    COUNTRY_SPECIFIC,
    EU_TACHOGRAPH,
    UNKNOWN,
}

export const mapTenantToTenantType = (tenant: Tenant | undefined): TenantType => {
    if (tenant?.startsWith('rio-eu.')) {
        return TenantType.EU_TACHOGRAPH;
    } else if (tenant?.startsWith('rio-brazil.')) {
        return TenantType.COUNTRY_SPECIFIC;
    } else {
        return TenantType.UNKNOWN;
    }
};

export const driverCardOrLicenseIntlMessage = (tenant: Tenant | undefined, intl: IntlShape) => {
    const tenantType = mapTenantToTenantType(tenant);
    if (tenantType === TenantType.EU_TACHOGRAPH) {
        return intl.formatMessage({ id: 'intl-msg:card' });
    } else if (tenantType === TenantType.COUNTRY_SPECIFIC) {
        return intl.formatMessage({ id: 'intl-msg:license' });
    } else {
        return '';
    }
};
