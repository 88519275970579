import { useState } from 'react';
import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';
import {
    DriverMultiSelectPropertiesFromDispatch,
    DriverMultiSelectPropertiesFromState,
} from '../containers/DriverMultiSelectContainer';
import { DriverMultiSelectAddDialogBodyContainer } from '../containers/DriverMultiSelectAddDialogBodyContainer';
import { DriverMultiSelectRemoveDialogBodyContainer } from '../containers/DriverMultiSelectRemoveDialogBodyContainer';
import { DriverStatus } from '../../../../../types';
import {
    DriverMultiSelectChangeStatusDialogBody,
    DriverMultiSelectChangeStatusValue,
} from './DriverMultiSelectChangeStatusDialogBody';
import { DriverMultiSelectDeleteDialogBody } from './DriverMultiSelectDeleteDialogBody';

export type DriverMultiSelectProperties = DriverMultiSelectOwnProps &
    DriverMultiSelectPropertiesFromState &
    DriverMultiSelectPropertiesFromDispatch &
    WrappedComponentProps;

interface DriverMultiSelectOwnProps {
    selectedDriverIds: string[];
    toggleAllCheckboxes: (enable: boolean) => void;
}

export const DriverMultiSelect = (props: DriverMultiSelectProperties) => {
    const {
        selectedDriverIds,
        toggleAllCheckboxes,
        drivers,
        tagsToCreate,
        tagsToAdd,
        tagsToRemove,
        createTagsAndAddTagsToDrivers,
        clearMultiSelectTags,
        removeTagsFromDrivers,
        archiveDrivers,
        activateDrivers,
        usersToDelete,
        deleteDriver,
        users,
        currentDriverId,
        setUsersToDelete,
        toggleDeleteUserDialog,
    } = props;

    const [showAddGroupsDialog, setShowAddGroupsDialog] = useState<boolean>(false);
    const [showRemoveGroupsDialog, setShowRemoveGroupsDialog] = useState<boolean>(false);
    const [showArchiveDialog, setShowArchiveDialog] = useState<boolean>(false);
    const [showActivateDialog, setShowActivateDialog] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    const confirmAddingToGroups = () => {
        createTagsAndAddTagsToDrivers(selectedDriverIds, drivers[0].accountId, tagsToCreate, tagsToAdd);
    };

    const confirmRemovingFromGroups = () => {
        const selectedDrivers = drivers.filter((driver) => selectedDriverIds.includes(driver.driverId));
        removeTagsFromDrivers(selectedDrivers, tagsToRemove);
    };

    const archiveSelectedDrivers = (removeUsers: boolean = false) => {
        const selectedDrivers = drivers
            .filter((driver) => selectedDriverIds.includes(driver.driverId))
            .filter((driver) => driver.status === DriverStatus.ACTIVE);

        archiveDrivers(currentDriverId, selectedDrivers, removeUsers);
        return selectedDrivers;
    };

    const initiateArchivingOfDrivers = () => {
        const driverSubjects = drivers
            .filter((driver) => driver.status !== DriverStatus.ARCHIVED)
            .filter((driver) => selectedDriverIds.includes(driver.driverId))
            .filter((selectedDriver) => selectedDriver.subject !== undefined)
            .map((driver) => driver.subject);
        if (driverSubjects.length > 0) {
            const usersToDeleteInternal = users.filter((user) => driverSubjects.includes(user.subject));
            setUsersToDelete(usersToDeleteInternal);
        }
        setShowArchiveDialog(true);
    };

    const confirmArchiveDrivers = () => {
        archiveSelectedDrivers();
        if (usersToDelete.length > 0) {
            toggleDeleteUserDialog(true);
        }
    };

    const confirmActivateDrivers = () => {
        const selectedDrivers = drivers
            .filter((driver) => selectedDriverIds.includes(driver.driverId))
            .filter((driver) => driver.status === DriverStatus.ARCHIVED);
        activateDrivers(currentDriverId, selectedDrivers);
    };

    const initiateDeletionOfDrivers = () => {
        const driverSubjects = drivers
            .filter((driver) => selectedDriverIds.includes(driver.driverId))
            .filter((selectedDriver) => selectedDriver.subject !== undefined)
            .map((driver) => driver.subject);
        if (driverSubjects.length > 0) {
            const usersToDeleteInternal = users.filter((user) => driverSubjects.includes(user.subject));
            setUsersToDelete(usersToDeleteInternal);
        }
        setShowDeleteDialog(true);
    };

    const confirmDeleteDrivers = () => {
        if (usersToDelete.length > 0) {
            toggleDeleteUserDialog(true);
        }
        deleteSelectedDrivers();
    };

    const deleteSelectedDrivers = () => {
        const selectedDrivers = drivers.filter((driver) => selectedDriverIds.includes(driver.driverId));
        for (const driver of selectedDrivers) {
            deleteDriver(driver);
        }
    };

    const cancelAddingToGroups = () => {
        setShowAddGroupsDialog(false);
        clearMultiSelectTags();
    };

    const cancelRemovingFromGroups = () => {
        setShowRemoveGroupsDialog(false);
        clearMultiSelectTags();
    };

    const cancelArchiveDrivers = () => {
        setUsersToDelete([]);
        setShowArchiveDialog(false);
    };

    const cancelActivateDrivers = () => {
        setShowActivateDialog(false);
    };

    const cancelDeleteDrivers = () => {
        setUsersToDelete([]);
        setShowDeleteDialog(false);
    };

    const selectedDriversToArchiveCount = drivers
        .filter((driver) => selectedDriverIds.includes(driver.driverId))
        .filter((driver) => driver.status === DriverStatus.ACTIVE).length;
    const selectedDriversToActivateCount = drivers
        .filter((driver) => selectedDriverIds.includes(driver.driverId))
        .filter((driver) => driver.status === DriverStatus.ARCHIVED).length;

    const dropDownItems = [
        {
            value: (
                <div data-testid="drivers-table-multiselect-add-to-group-button">
                    <span className="rioglyph rioglyph-plus-sign margin-right-10 text-size-18" />
                    <FormattedMessage id={'intl-msg:multiSelect.addToGroup'} /> ({selectedDriverIds.length})
                </div>
            ),
            disabled: selectedDriverIds.length === 0,
            onSelect: () => setShowAddGroupsDialog(true),
        },
        {
            value: (
                <div data-testid="drivers-table-multiselect-remove-from-group-button">
                    <span className="rioglyph rioglyph-minus-sign margin-right-10 text-size-18" />
                    <FormattedMessage id={'intl-msg:multiSelect.removeFromGroup'} /> ({selectedDriverIds.length})
                </div>
            ),
            disabled: selectedDriverIds.length === 0,
            onSelect: () => setShowRemoveGroupsDialog(true),
        },
        {
            value: (
                <div data-testid="drivers-table-multiselect-activate-button">
                    <span className="rioglyph rioglyph-minus-sign margin-right-10 text-size-18" />
                    <FormattedMessage id={'intl-msg:multiSelect.activate.title'} /> ({selectedDriversToActivateCount})
                </div>
            ),
            disabled: selectedDriversToActivateCount === 0,
            onSelect: () => setShowActivateDialog(true),
        },
        {
            value: (
                <div data-testid="drivers-table-multiselect-archive-button">
                    <span className="rioglyph rioglyph-minus-sign margin-right-10 text-size-18" />
                    <FormattedMessage id={'intl-msg:multiSelect.archive.title'} /> ({selectedDriversToArchiveCount})
                </div>
            ),
            disabled: selectedDriversToArchiveCount === 0,
            onSelect: initiateArchivingOfDrivers,
        },
        {
            value: (
                <div data-testid="drivers-table-multiselect-delete-button">
                    <span className="rioglyph rioglyph-trash margin-right-10 text-size-18 text-color-danger" />
                    <FormattedMessage id={'intl-msg:multiSelect.delete.title'} /> ({selectedDriverIds.length})
                </div>
            ),
            disabled: selectedDriverIds.length === 0,
            onSelect: initiateDeletionOfDrivers,
        },
        {
            divider: true,
        },
        {
            value: (
                <div data-testid="drivers-table-multiselect-select-all-button">
                    <FormattedMessage id={'intl-msg:multiSelect.selectAll'} />
                </div>
            ),
            onSelect: () => toggleAllCheckboxes(true),
        },
        {
            value: (
                <div data-testid="drivers-table-multiselect-deselect-all-button">
                    <FormattedMessage id={'intl-msg:multiSelect.deselectAll'} />
                </div>
            ),
            onSelect: () => toggleAllCheckboxes(false),
        },
    ];

    return (
        <div data-testid={'multiSelectDropdownButton'}>
            <ButtonDropdown
                id={'multi-select-dropdown'}
                title={<span className="rioglyph rioglyph rioglyph-checkboxes" />}
                className={'btn-s'}
                bsStyle={'link'}
                iconOnly={true}
                items={dropDownItems}
            />
            <ConfirmationDialog
                show={showAddGroupsDialog}
                title={<FormattedMessage id={'intl-msg:multiSelect.addDialog.title'} />}
                bsSize={'sm'}
                content={<DriverMultiSelectAddDialogBodyContainer selectedDriverIds={selectedDriverIds} />}
                onClickConfirm={confirmAddingToGroups}
                onClickCancel={cancelAddingToGroups}
                cancelButtonText={<FormattedMessage id={'intl-msg:multiSelect.addDialog.cancel'} />}
                confirmButtonText={<FormattedMessage id={'intl-msg:multiSelect.addDialog.confirm'} />}
                useOverflow={false}
                disableConfirm={false}
            />
            <ConfirmationDialog
                show={showRemoveGroupsDialog}
                title={<FormattedMessage id={'intl-msg:multiSelect.removeDialog.title'} />}
                bsSize={'sm'}
                content={<DriverMultiSelectRemoveDialogBodyContainer selectedDriverIds={selectedDriverIds} />}
                onClickConfirm={confirmRemovingFromGroups}
                onClickCancel={cancelRemovingFromGroups}
                cancelButtonText={<FormattedMessage id={'intl-msg:multiSelect.addDialog.cancel'} />}
                confirmButtonText={<FormattedMessage id={'intl-msg:multiSelect.addDialog.confirm'} />}
                useOverflow={true}
                disableConfirm={false}
            />
            <ConfirmationDialog
                show={showActivateDialog}
                title={<FormattedMessage id={'intl-msg:multiSelect.activate.title'} />}
                bsSize={'sm'}
                content={
                    <DriverMultiSelectChangeStatusDialogBody
                        selectedDriversCount={selectedDriversToActivateCount}
                        newStatus={DriverMultiSelectChangeStatusValue.ACTIVATE}
                    />
                }
                onClickConfirm={confirmActivateDrivers}
                onClickCancel={cancelActivateDrivers}
                cancelButtonText={<FormattedMessage id={'intl-msg:multiSelect.addDialog.cancel'} />}
                confirmButtonText={<FormattedMessage id={'intl-msg:multiSelect.addDialog.confirm'} />}
                useOverflow={true}
                disableConfirm={false}
            />
            <ConfirmationDialog
                show={showDeleteDialog}
                title={<FormattedMessage id={'intl-msg:multiSelect.deleteDialog.title'} />}
                bsSize={'sm'}
                content={<DriverMultiSelectDeleteDialogBody selectedDriversCount={selectedDriverIds.length} />}
                onClickConfirm={confirmDeleteDrivers}
                onClickCancel={cancelDeleteDrivers}
                cancelButtonText={<FormattedMessage id={'intl-msg:multiSelect.deleteDialog.cancel'} />}
                confirmButtonText={<FormattedMessage id={'intl-msg:multiSelect.deleteDialog.confirm'} />}
                useOverflow={true}
                disableConfirm={false}
            />
            <ConfirmationDialog
                show={showArchiveDialog}
                title={<FormattedMessage id={'intl-msg:multiSelect.archive.title'} />}
                bsSize={'sm'}
                content={
                    <DriverMultiSelectChangeStatusDialogBody
                        selectedDriversCount={selectedDriversToArchiveCount}
                        newStatus={DriverMultiSelectChangeStatusValue.ARCHIVE}
                    />
                }
                onClickConfirm={confirmArchiveDrivers}
                onClickCancel={cancelArchiveDrivers}
                cancelButtonText={<FormattedMessage id={'intl-msg:multiSelect.addDialog.cancel'} />}
                confirmButtonText={<FormattedMessage id={'intl-msg:multiSelect.addDialog.confirm'} />}
                useOverflow={true}
                disableConfirm={false}
            />
        </div>
    );
};
