import Switch from '@rio-cloud/rio-uikit/Switch';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { NewIdentification, SortByField } from '../../../../../../../types';
import { IdentificationFieldRow } from '../../../../../form/components/IdentificationFieldRow';
import {
    INTL_FIELD_DRIVER_STATUS_ACTIVE,
    INTL_FIELD_DRIVER_STATUS_ARCHIVED,
    INTL_FIELD_EMAIL,
    INTL_FIELD_FIRST_NAME,
    INTL_FIELD_LAST_NAME,
    INTL_FIELD_PHONE,
    INTL_FIELD_STATUS,
    PROP_EMAIL,
    PROP_FIRST_NAME,
    PROP_LAST_NAME,
    PROP_PHONE_NUMBER,
} from '../../../../../schema';
import { driverCardOrLicenseIntlMessage } from '../../../../../tenantTypes';
import { isActiveDriver } from '../../../../../utils';
import { FormInputGroup } from '../../../commonElements/FormInputGroup';
import { DriverInfoFormProperties } from '../../types/driverInfoFormTypes';

const getMessageById = (id: string | undefined) => id && <FormattedMessage id={id} />;
const getValueFromEventTarget = (event: React.ChangeEvent<HTMLInputElement>) => event.target.value;

export const DriverInfoForm = (props: DriverInfoFormProperties) => {
    const { formErrors, selectedDriver, fetchDriverInProgress, showUserCreation, intl } = props;

    const toggleIdentificationInProgress = (newState: boolean) => () => {
        props.setShowNotificationCreation(newState);
    };

    const handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = getValueFromEventTarget(event);
        props.changeFirstName(value);
    };

    const handleLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = getValueFromEventTarget(event);
        props.changeLastName(value);
    };

    const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = getValueFromEventTarget(event).trim();
        props.changeEmail(value);
    };

    const handlePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = getValueFromEventTarget(event).trim();
        props.changePhoneNumber(value);
    };

    const handleStatusChange = () => {
        props.changeStatus();
    };

    const isActive = isActiveDriver(selectedDriver);
    const buttonStateChange = fetchDriverInProgress ? () => {} : handleStatusChange;

    const toggleDeletion = (identificationId: string) => () => {
        props.togglePendingDeletion(identificationId);
    };
    const { changeNewIdentificationValue } = props;
    const toggleChangeNewIdentification = useCallback(
        (newIdentification: NewIdentification) => {
            changeNewIdentificationValue(newIdentification);
        },
        [changeNewIdentificationValue]
    );

    const disableFields = showUserCreation || fetchDriverInProgress;

    return (
        <div className={'form-group'}>
            <FormInputGroup
                fieldError={getMessageById(formErrors[PROP_FIRST_NAME])}
                fieldLabel={<FormattedMessage id={INTL_FIELD_FIRST_NAME} />}
                fieldName={SortByField.FIELD_FIRST_NAME}
                onChange={handleFirstName}
                value={selectedDriver?.firstName !== undefined ? selectedDriver.firstName : ''}
                readOnly={disableFields}
                dataAttribute="firstNameFormInputField"
                type={'text'}
            />
            <FormInputGroup
                fieldError={getMessageById(formErrors[PROP_LAST_NAME])}
                fieldLabel={
                    <span>
                        <FormattedMessage id={INTL_FIELD_LAST_NAME} />
                        {'*'}
                    </span>
                }
                fieldName={SortByField.FIELD_LAST_NAME}
                onChange={handleLastName}
                value={selectedDriver?.lastName !== undefined ? selectedDriver.lastName : ''}
                readOnly={disableFields}
                dataAttribute="lastNameFormInputField"
                type={'text'}
            />
            <FormInputGroup
                fieldError={getMessageById(formErrors[PROP_EMAIL])}
                fieldLabel={<FormattedMessage id={INTL_FIELD_EMAIL} />}
                fieldName={SortByField.FIELD_EMAIL}
                icon={'rioglyph rioglyph-envelope'}
                onChange={handleEmail}
                value={selectedDriver?.email !== undefined ? selectedDriver.email : ''}
                readOnly={disableFields}
                type={'email'}
            />
            <FormInputGroup
                fieldError={getMessageById(formErrors[PROP_PHONE_NUMBER])}
                fieldLabel={<FormattedMessage id={INTL_FIELD_PHONE} />}
                fieldName={SortByField.FIELD_PHONE}
                icon={'rioglyph rioglyph-earphone'}
                onChange={handlePhoneNumber}
                value={selectedDriver?.phoneNumber !== undefined ? selectedDriver.phoneNumber : ''}
                readOnly={disableFields}
                dataAttribute="phoneNumberFormInputField"
                type={'text'}
            />
            {selectedDriver?.identifications?.length === 0 && !props.showIdentificationCreation ? (
                <button
                    className="btn btn-default margin-top-10 margin-bottom-15"
                    type="button"
                    onClick={toggleIdentificationInProgress(true)}
                    data-testid={'startAddingIdentificationButton'}
                >
                    <FormattedMessage id={'intl-msg:sidebar.DriverInfoForm.addIdentification'} />
                </button>
            ) : (
                <div className="form-group">
                    <div className="display-flex justify-content-between align-items-baseline">
                        <label className="control-label" htmlFor="driverIdentification">
                            {driverCardOrLicenseIntlMessage(props.tenant, intl)}
                        </label>

                        {props.showIdentificationCreation ? (
                            <button
                                className="btn btn-link btn-link-inline text-size-12 hover-text-decoration-underline"
                                type="button"
                                onClick={toggleIdentificationInProgress(false)}
                                data-testid={'stopAddingIdentificationButton'}
                            >
                                <FormattedMessage id={'intl-msg:sidebar.DriverInfoForm.collapseAddIdentification'} />
                            </button>
                        ) : (
                            <button
                                className="btn btn-link btn-link-inline text-size-12 hover-text-decoration-underline"
                                type="button"
                                onClick={toggleIdentificationInProgress(true)}
                                data-testid={'startAddingIdentificationButton'}
                            >
                                <FormattedMessage id={'intl-msg:sidebar.DriverInfoForm.expandAddIdentification'} />
                            </button>
                        )}
                    </div>

                    {props.showIdentificationCreation && (
                        <div className="panel panel-default panel-body padding-top-5 padding-bottom-0">
                            <IdentificationFieldRow
                                className={'col-12 form-group'}
                                tenant={props.tenant}
                                parentDialogShown={true}
                                existingDrivers={props.existingDrivers}
                                onIdentificationChangeCallback={toggleChangeNewIdentification}
                                currentDriver={props.selectedDriver}
                            />
                        </div>
                    )}

                    {selectedDriver?.identifications
                        ?.slice()
                        .sort((a, b) => a.value.localeCompare(b.value))
                        .map((identification, index) => (
                            <div className="input-group margin-bottom-10" key={index}>
                                <span className="input-group-addon">
                                    <span className="rioglyph rioglyph-driver" />
                                </span>
                                <input
                                    readOnly={true}
                                    className={`form-control ${
                                        props.pendingDeletions.includes(identification.id)
                                            ? 'opacity-50 text-decoration-line-through'
                                            : ''
                                    }`}
                                    id="driverIdentification"
                                    name="driverIdentification"
                                    type="text"
                                    value={identification.value}
                                    data-testid="existingDriverIdentifications"
                                />
                                <span className="input-group-btn">
                                    <button
                                        className="btn btn-icon-only btn-default"
                                        type="button"
                                        onClick={toggleDeletion(identification.id)}
                                        data-testid={'identificationDelete'}
                                    >
                                        <span
                                            className={`rioglyph ${
                                                props.pendingDeletions.includes(identification.id)
                                                    ? 'rioglyph-revert'
                                                    : 'rioglyph-trash'
                                            }`}
                                        />
                                    </button>
                                </span>
                            </div>
                        ))}
                </div>
            )}
            <div className={'form-group'}>
                <label className={'control-label'} htmlFor={SortByField.FIELD_STATUS}>
                    <FormattedMessage id={INTL_FIELD_STATUS} />
                </label>
                <div>
                    <span className={'margin-right-10'}>
                        <Switch
                            checked={isActive}
                            keyName={'driver-state-switch'}
                            onChange={buttonStateChange}
                            minWidth={50}
                            enabledText={intl.formatMessage({ id: INTL_FIELD_DRIVER_STATUS_ACTIVE })}
                            disabledText={intl.formatMessage({ id: INTL_FIELD_DRIVER_STATUS_ARCHIVED })}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};
