import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';

interface FooterProperties {
    isSaveButtonEnabled: boolean;
    onSave: () => void;
    onDelete: () => void;
}

export const Footer = (props: FooterProperties) => {
    const { isSaveButtonEnabled, onSave, onDelete } = props;
    const deleteButton = (
        <button
            className="btn btn-danger btn-link"
            type="button"
            disabled={false}
            onClick={onDelete}
            data-testid="detailViewDeleteButton"
        >
            <span className="rioglyph rioglyph-trash text-color-danger margin-right-5" />
            <span>
                <FormattedMessage id={'intl-msg:delete'} />
            </span>
        </button>
    );

    return (
        <React.Fragment>
            <div className="btn-toolbar pull-left">{deleteButton}</div>
            <div className="btn-toolbar pull-right">
                <Button
                    className={!isSaveButtonEnabled ? 'disabled' : ''}
                    onClick={onSave}
                    type={'button'}
                    data-testid={'detailViewSaveButton'}
                >
                    <FormattedMessage id={'intl-msg:save'} />
                </Button>
            </div>
        </React.Fragment>
    );
};
