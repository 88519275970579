import { FormattedMessage } from 'react-intl';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';

export const NotFound = () => (
    <div className={'display-flex justify-content-center'}>
        <NotFoundState
            headline={<FormattedMessage id={'intl-msg:emptySearchResult'} />}
            message={<FormattedMessage id={'intl-msg:emptySearchResult.message'} />}
        />
    </div>
);
