import { ThunkDispatch } from 'redux-thunk';
import { Driver, NewIdentification, Tag, TagToCreate } from '../../../types';
import { api } from '../../api';
import { ErrorOrigin, ErrorResponse, getErrorCode } from '../../apiUtils';
import { clearAllTags } from '../drivers/sidebar/tabs/groups/groupSlice';
import { showErrorNotification, showSuccessNotification } from '../notifications/notifications';
import { fetchDriver, fetchDrivers, fetchUsers } from './fetchThunks';
import { addIdentification, deleteIdentifications } from './identificationThunks';
import { addExistingTagsToDriver, createNewTagsAndAddToDriver, removeTagsFromDriver } from './tagDriversThunks';
import { saveDriverFailed, saveDriverInProgress, saveDriverSuccess } from '../drivers/sidebar/sidebarSlice';

export const updateDriver = (
    driverToSave: Driver,
    tagsToCreate: TagToCreate[],
    tagsToAdd: Tag[],
    tagsToRemove: Tag[],
    newIdentification: NewIdentification | null,
    identificationIdsPendingForDeletion: Set<string>
) => {
    return (dispatch: ThunkDispatch<any, any, any>) => {
        dispatch(saveDriverInProgress());

        return addExistingTagsToDriver(tagsToAdd, driverToSave.driverId)
            .then(() => {
                return removeTagsFromDriver(tagsToRemove, driverToSave.driverId);
            })
            .then(() => {
                return createNewTagsAndAddToDriver(tagsToCreate, driverToSave.driverId, driverToSave.accountId);
            })
            .then(() => {
                return deleteIdentifications(driverToSave.driverId, identificationIdsPendingForDeletion);
            })
            .then(() => {
                if (newIdentification?.value) {
                    return addIdentification(driverToSave.driverId, newIdentification);
                }
                return;
            })
            .then(() => {
                return api.saveDriver(driverToSave);
            })
            .then((response) => {
                showSuccessNotification('intl-msg:save.success');
                dispatch(saveDriverSuccess(driverToSave));

                // fetch list of users and drivers again as the state may have changed
                dispatch(fetchUsers());
                dispatch(fetchDrivers());

                // necessary to show new tags of driver directly in detail view
                dispatch(fetchDriver(driverToSave.driverId));

                dispatch(clearAllTags());

                return response;
            })
            .catch((errorResponse: ErrorResponse) => {
                showErrorNotification(getErrorCode(errorResponse, ErrorOrigin.DRIVER_ADMINISTRATION));
                dispatch(saveDriverFailed());
            });
    };
};
