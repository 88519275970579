import { FormattedMessage } from 'react-intl';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import ToggleButton from '@rio-cloud/rio-uikit/ToggleButton';
import { DriverStatus } from '../../../../../types';
import {
    DriversTableFilterPropertiesFromDispatch,
    DriversTableFilterPropertiesFromState,
} from '../containers/DriversTableFilterContainer';
import { useEffect, useState } from 'react';

export type DriversTableFilterProps = DriversTableFilterPropertiesFromState & DriversTableFilterPropertiesFromDispatch;

export const DriversTableFilter = (props: DriversTableFilterProps) => {
    const [open, setOpen] = useState(false);
    const [activeDriversChecked, setActiveDriversChecked] = useState(false);
    const [archivedDriversChecked, setArchivedDriversChecked] = useState(false);

    useEffect(() => {
        setActiveDriversChecked(props.driverStatuses.includes(DriverStatus.ACTIVE));
        setArchivedDriversChecked(props.driverStatuses.includes(DriverStatus.ARCHIVED));
    }, [props.driverStatuses]);

    const closeDialog = () => setOpen(false);
    const openDialog = () => setOpen(true);

    const toggleActiveDriversCheckBox = () => setActiveDriversChecked(!activeDriversChecked);
    const toggleArchivedDriversCheckBox = () => setArchivedDriversChecked(!archivedDriversChecked);

    const cancelFilterChanges = () => {
        setActiveDriversChecked(props.driverStatuses.includes(DriverStatus.ACTIVE));
        setArchivedDriversChecked(props.driverStatuses.includes(DriverStatus.ARCHIVED));
        closeDialog();
    };

    const applyFilterChanges = () => {
        dispatchActionsAndIntents();
        closeDialog();
    };

    const dispatchActionsAndIntents = () => {
        const { changeDriverStatuses } = props;

        const driverStatusesUpdate: DriverStatus[] = [];
        if (activeDriversChecked) {
            driverStatusesUpdate.push(DriverStatus.ACTIVE);
        }
        if (archivedDriversChecked) {
            driverStatusesUpdate.push(DriverStatus.ARCHIVED);
        }
        changeDriverStatuses(driverStatusesUpdate);
    };

    const getFooter = () => {
        return (
            <div>
                <button type={'button'} className={'btn btn-default'} onClick={cancelFilterChanges}>
                    <FormattedMessage id={'intl-msg:abort'} />
                </button>
                <button
                    type={'button'}
                    className={'btn btn-primary'}
                    onClick={applyFilterChanges}
                    data-testid={'confirmFilterOptionsButton'}
                >
                    {<FormattedMessage id={'intl-msg:apply'} />}
                </button>
            </div>
        );
    };

    const getBody = () => {
        return (
            <div>
                <p className={'text-bold text-color-darkest'}>
                    <span>
                        <FormattedMessage id={'intl-msg:status'} />
                    </span>
                </p>
                <div className={'form-group'} data-testid={'activeDriversCheckBox'}>
                    <Checkbox name={'active'} onClick={toggleActiveDriversCheckBox} checked={activeDriversChecked}>
                        <FormattedMessage id={'intl-msg:status.active'} />
                    </Checkbox>
                </div>
                <div className={'form-group'} data-testid={'archivedDriversCheckBox'}>
                    <Checkbox
                        name={'archived'}
                        onClick={toggleArchivedDriversCheckBox}
                        checked={archivedDriversChecked}
                    >
                        <FormattedMessage id={'intl-msg:status.archived'} />
                    </Checkbox>
                </div>
            </div>
        );
    };

    return (
        <div>
            <ToggleButton active={open} onClick={openDialog} data-testid={'tableFilterOptionsButton'}>
                <span className={'rioglyph rioglyph-filter'} />
                <span>
                    <FormattedMessage id={'intl-msg:filter'} />
                </span>
            </ToggleButton>
            <Dialog
                show={open}
                title={<FormattedMessage id={'intl-msg:filter.options'} />}
                body={getBody()}
                footer={getFooter()}
                bsSize={Dialog.SIZE_SM}
                onHide={cancelFilterChanges}
                showCloseButton={true}
            />
        </div>
    );
};
