import { connect } from 'react-redux';
import { Tag } from '../../../../../types';
import { getAllTagsInAccount } from '../../../../Drivers.selectors';
import { DriverTagsInRow } from '../components/DriverTagsInRow';
import { RootState } from '../../../../../configuration/setup/store';

export interface DriverTagsInRowPropertiesFromState {
    allTagsInAccount: Tag[];
}

export interface DriverTagsInRowPropertiesFromDispatch {}

const mapStateToProps = (state: RootState): DriverTagsInRowPropertiesFromState => ({
    allTagsInAccount: getAllTagsInAccount(state),
});

export const DriverTagsInRowContainer = connect(mapStateToProps)(DriverTagsInRow);
