import { useEffect, useState } from 'react';
import AutoSuggest from '@rio-cloud/rio-uikit/AutoSuggest';
import classNames from 'classnames';
import { formatUserForDisplay } from '../../../../../utils';
import {
    UserAutoSuggestPropertiesFromDispatch,
    UserAutoSuggestPropertiesFromState,
} from '../containers/UserAutoSuggestContainer';
import { Driver, User } from '../../../../../../../types';
import { useIntl } from 'react-intl';
import { AutoSuggestSuggestion } from '@rio-cloud/rio-uikit/types';

interface UserAutoSuggestOwnProperties {
    assignedUser: User | undefined;
    onSelectionChange: (user: User) => void;
    onUserRemoved: () => void;
}

type UserAutoSuggestProperties = UserAutoSuggestPropertiesFromState &
    UserAutoSuggestPropertiesFromDispatch &
    UserAutoSuggestOwnProperties;

export const UserAutoSuggest = (props: UserAutoSuggestProperties) => {
    const { users, drivers, disabled, assignedUser, onSelectionChange, onUserRemoved } = props;

    const [userSuggestions, setUserSuggestions] = useState<User[]>([]);
    const [userToDisplay, setUserToDisplay] = useState<string | undefined>(formatUserForDisplay(assignedUser));
    const noItemFoundMessage = '...';

    const intl = useIntl();

    useEffect(() => {
        setUserToDisplay(formatUserForDisplay(assignedUser));
    }, [assignedUser]);

    const getSuggestionValue = (suggestedUser: AutoSuggestSuggestion) => {
        return formatUserForDisplay(suggestedUser);
    };

    const renderSuggestion = (suggestion: AutoSuggestSuggestion) => {
        return <span>{formatUserForDisplay(suggestion)}</span>;
    };

    const onSuggestionSelected = (_: number, { suggestion }: AutoSuggestSuggestion) => {
        onSelectionChange(suggestion);
    };

    const getAssignableUsers = (): User[] => {
        return users.filter(
            (u: User) => !drivers.some((driver: Driver) => driver.subject && driver.subject === u.subject)
        );
    };

    const filterUsersInAccount = (usersToFilter: User[], inputValue: string) => {
        return inputValue
            .toLocaleLowerCase()
            .split(' ')
            .reduce(
                (prevResult, searchString) =>
                    prevResult.filter(
                        (userToFilter: User) =>
                            userToFilter.firstName.toLowerCase().includes(searchString) ||
                            userToFilter.lastName.toLowerCase().includes(searchString) ||
                            (userToFilter.email && userToFilter.email.toLowerCase().includes(searchString)) ||
                            (userToFilter.phoneNumber && userToFilter.phoneNumber.includes(searchString))
                    ),
                usersToFilter
            );
    };

    const onSuggestionsFetchRequested = (argument: { value: string }) => {
        const inputValue = argument.value ? argument.value.trim().toLowerCase() : '';
        setUserSuggestions(filterUsersInAccount(getAssignableUsers(), inputValue));
    };

    const handleChange = (_: any, { newValue }: any) => {
        setUserToDisplay(newValue);
    };

    const handleClear = () => {
        setUserSuggestions(getAssignableUsers());
        setUserToDisplay('');
        onUserRemoved();
    };

    const inactiveAutoSuggest = (
        <div className={'ClearableInput input-group mock'}>
            <input value={userToDisplay} className={'form-control withClearButton'} readOnly={true} type={'text'} />
            <span onClick={handleClear} className={'clearButton'}>
                <span className={'clearButtonIcon rioglyph rioglyph-remove-sign'} />
            </span>
        </div>
    );

    const inputProperties = {
        onChange: handleChange,
        onClear: handleClear,
        value: userToDisplay,
        disabled,
    };

    return (
        <div className={classNames('form-group')}>
            {assignedUser ? (
                inactiveAutoSuggest
            ) : (
                <AutoSuggest
                    className={'form-group'}
                    dropdownClassName={'bar'}
                    inputProps={{
                        ...inputProperties,
                        placeholder: intl.formatMessage({ id: 'intl-msg:assignExistingUserPlaceholder' }),
                    }}
                    suggestions={userSuggestions}
                    noItemMessage={noItemFoundMessage}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionSelected={onSuggestionSelected}
                    renderSuggestion={renderSuggestion}
                    getSuggestionValue={getSuggestionValue}
                />
            )}
        </div>
    );
};
