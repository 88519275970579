import { Driver, Tag, TagToCreate } from '../../../../../types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { DriverMultiSelectAddDialogBody } from '../components/DriverMultiSelectAddDialogBody';
import { getAllTagsInAccount } from '../../../../Drivers.selectors';
import { getDriversRtk } from '../../../appSlice';
import { AppDispatch, RootState } from '../../../../../configuration/setup/store';
import { setMultiSelectTagsToCreate, setMultiSelectTagToAdd } from '../../sidebar/tabs/groups/groupSlice';

export interface DriverMultiSelectAddDialogBodyPropertiesFromState {
    allTagsInAccount: Tag[];
    drivers: Driver[];
}

export interface DriverMultiSelectAddDialogBodyPropertiesFromDispatch {
    setTagsToAdd: (tag: Tag[]) => void;
    setTagsToCreate: (tagToCreate: TagToCreate[]) => void;
}

const mapStateToProps = (state: RootState): DriverMultiSelectAddDialogBodyPropertiesFromState => ({
    allTagsInAccount: getAllTagsInAccount(state),
    drivers: getDriversRtk(state as any),
});

const mapDispatchToProps = (dispatch: AppDispatch): DriverMultiSelectAddDialogBodyPropertiesFromDispatch => ({
    setTagsToAdd(tags: Tag[]) {
        dispatch(setMultiSelectTagToAdd(tags));
    },
    setTagsToCreate(tagsToCreate: TagToCreate[]) {
        dispatch(setMultiSelectTagsToCreate(tagsToCreate));
    },
});

export const DriverMultiSelectAddDialogBodyContainer = injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(DriverMultiSelectAddDialogBody)
);
