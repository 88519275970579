import React, { memo } from 'react';

interface EmailProps {
    email: string | undefined;
    onClick: (event: React.MouseEvent) => void;
}

export const Email = memo((props: EmailProps) => {
    const { email, onClick } = props;
    return (
        <a href={`mailto:${email}`} onClick={onClick}>
            {email}
        </a>
    );
});
Email.displayName = 'Email';
