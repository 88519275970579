import { connect } from 'react-redux';
import { DriversTableFilter } from '../components/DriversTableFilter';
import { changeDriverStatuses } from '../../../thunks/tableThunks';
import { DriverStatus } from '../../../../../types';
import { ThunkDispatch } from 'redux-thunk';
import { getDriverStatusesRtk } from '../../../appSlice';
import { RootState } from '../../../../../configuration/setup/store';

export interface DriversTableFilterPropertiesFromState {
    driverStatuses: DriverStatus[];
}

export interface DriversTableFilterPropertiesFromDispatch {
    changeDriverStatuses: (driverStatuses: DriverStatus[]) => void;
}

const mapStateToProps = (state: RootState): DriversTableFilterPropertiesFromState => ({
    driverStatuses: getDriverStatusesRtk(state as any),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DriversTableFilterPropertiesFromDispatch => ({
    changeDriverStatuses: (driverStatuses: DriverStatus[]) => {
        dispatch(changeDriverStatuses(driverStatuses));
    },
});

export const DriversTableFilterContainer = connect(mapStateToProps, mapDispatchToProps)(DriversTableFilter);
