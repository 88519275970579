import { connect } from 'react-redux';
import { Driver, User } from '../../../../../../../types';
import { UserAutoSuggest } from '../components/UserAutoSuggest';
import { getDriversRtk, getUsersRtk } from '../../../../../appSlice';
import { isFetchDriverInProgressRtk } from '../../../sidebarSlice';

export interface UserAutoSuggestPropertiesFromState {
    users: User[];
    drivers: Driver[];
    disabled: boolean;
}

export interface UserAutoSuggestPropertiesFromDispatch {}

const mapStateToProps = (state: any): UserAutoSuggestPropertiesFromState => {
    return {
        users: getUsersRtk(state),
        drivers: getDriversRtk(state),
        disabled: isFetchDriverInProgressRtk(state),
    };
};

const mapDispatchToProps = (dispatch: any): UserAutoSuggestPropertiesFromDispatch => ({});

export const UserAutoSuggestContainer = connect(mapStateToProps, mapDispatchToProps)(UserAutoSuggest);
