import { FormattedMessage } from 'react-intl';

interface DriverMultiSelectDeleteDialogBodyProps {
    selectedDriversCount: number;
}

export const DriverMultiSelectDeleteDialogBody = (props: DriverMultiSelectDeleteDialogBodyProps) => {
    return (
        <>
            <div data-testid={'driver-multi-select-delete-dialog-body'}>
                <FormattedMessage
                    id={'intl-msg.multiSelect.deleteDialog.content'}
                    values={{ amount: props.selectedDriversCount }}
                />
            </div>
        </>
    );
};
