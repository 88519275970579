import { Driver, IdentificationTypes } from '../../../types';
import { DriverCardCountry, mapDriverCardCountryToPrefix } from './driverCardCountry';

export const isValidDriverCardNumber = (driverCardNumber: string): boolean => {
    // see: https://collaboration.msi.audi.com/confluence/pages/viewpage.action?spaceKey=PLTFRMDOC&title=Driver+Identifications#DriverIdentifications-eu-tachograph-card
    // Official Journal of the European Communities  5.8.2002
    // L 207/61 and L 207/96
    const validIA5StringCharacters = '[a-zA-Z0-9 .:,;_<=>@^`|/(){}[\\]$%&"?!#*+~\'-\\\\]';
    const driverIdentification = `${validIA5StringCharacters}{14}`;
    const cardReplacementIndex = '[0-9A-Z]';
    const cardRenewalIndex = '[0-9A-Z]';
    const regex = RegExp(`^${driverIdentification}${cardReplacementIndex}${cardRenewalIndex}$`);
    return regex.test(driverCardNumber);
};

export const euDriverWithConflictingIdentification = (
    country: DriverCardCountry,
    cardNumber: string,
    existingDriversInAccount: Driver[],
    currentDriver: Driver | null
): Driver[] => {
    const driverIdentification = mapDriverCardCountryToPrefix(country) + cardNumber;
    return existingDriversInAccount.filter((driver) =>
        driver.identifications
            .filter((identification) => identification.type === IdentificationTypes.EU_TACHOGRAPH_CARD)
            .some((identification) => {
                // exclude renewal and replacement counters
                if (driver.driverId === currentDriver?.driverId) {
                    return identification.value === driverIdentification;
                } else {
                    return isEqualApartFromLastTwoCharacters(identification.value, driverIdentification);
                }
            })
    );
};

export const invalidDriverCardNumber = (driverCardNumber: string | undefined): boolean =>
    !driverCardNumber || !isValidDriverCardNumber(driverCardNumber);
export const driversWithSimilarDriverCards = (
    selectedCountry: DriverCardCountry,
    driverCardNumber: string | undefined,
    existingDrivers: Driver[],
    currentDriver: Driver | null
): Driver[] =>
    driverCardNumber
        ? euDriverWithConflictingIdentification(selectedCountry, driverCardNumber, existingDrivers, currentDriver)
        : [];

const isEqualApartFromLastTwoCharacters = (cardA: string, cardB: string): boolean => {
    return cardA.substring(0, cardA.length - 2) === cardB.substring(0, cardB.length - 2);
};
