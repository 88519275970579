import React, { memo } from 'react';

interface TelephoneProperties {
    phoneNumber: string | undefined;
    onClick: (event: React.MouseEvent) => void;
}

export const Telephone = memo((props: TelephoneProperties) => {
    const { phoneNumber, onClick } = props;
    return (
        <a href={`tel:${phoneNumber}`} onClick={onClick}>
            {phoneNumber}
        </a>
    );
});
Telephone.displayName = 'Telephone';
