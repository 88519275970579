import Select, { SelectOption } from '@rio-cloud/rio-uikit/Select';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { DriverIdentificationFieldProps, IdentificationTypes } from '../../../../types';
import { existingDriverErrorString } from '../../dialogs/components/CreateDriverDialog';
import {
    countryPhraseID,
    DriverCardCountry,
    genericCountries,
    getCountryFromLocale,
    mapDriverCardCountryToPrefix,
    specificCountries,
} from '../../dialogs/driverCardCountry';
import { driversWithSimilarDriverCards, invalidDriverCardNumber } from '../../dialogs/driverCardValidation';
import { FormInputGroup } from '../../drivers/sidebar/commonElements/FormInputGroup';

const driverCardHelpLink = 'https://rio.cloud/en/glossar/driver-card?noCookie=1';

export const EuTachographIdentificationField = (props: DriverIdentificationFieldProps) => {
    const intl = useIntl();
    const [selectedCountry, setSelectedCountry] = useState<DriverCardCountry>(getCountryFromLocale(intl.locale));
    const [driverCardNumber, setDriverCardNumber] = useState<string | undefined>();

    useEffect(() => {
        setDriverCardNumber(undefined);
    }, [props.parentDialogShown]);

    const { onIdentificationChangeCallback, existingDrivers, currentDriver } = props;
    useEffect(() => {
        onIdentificationChangeCallback({
            value: generateIdentification(selectedCountry, driverCardNumber),
            isValid:
                invalidDriverCardNumber(driverCardNumber) ||
                driversWithSimilarDriverCards(selectedCountry, driverCardNumber, existingDrivers, currentDriver)
                    .length > 0,
            type: IdentificationTypes.EU_TACHOGRAPH_CARD,
        });
    }, [selectedCountry, driverCardNumber, existingDrivers, currentDriver, onIdentificationChangeCallback]);

    const countryCodeOptions: SelectOption[] = sortByTranslation(intl, specificCountries)
        .map(
            (v: DriverCardCountry): SelectOption => ({
                id: v,
                label: <FormattedMessage id={countryPhraseID(v)} />,
            })
        )
        .concat({
            id: 'OTHER_HEADER',
            label: <FormattedMessage id={'intl-msg:createDriver.addDriver.dialog.euDriverCard.genericCountryHeader'} />,
            header: true,
        })
        .concat(
            sortByTranslation(intl, genericCountries).map((v: DriverCardCountry) => ({
                id: v,
                label: <FormattedMessage id={countryPhraseID(v)} />,
            }))
        );

    const handleSelectedCountryChange = (o: SelectOption) => setSelectedCountry(o.id as DriverCardCountry);
    const handleDriverCardNumberChange = (e: ChangeEvent<HTMLInputElement>) =>
        setDriverCardNumber(e.target.value.toUpperCase());

    const driverCardNumberErrorMessage = (): ReactElement | undefined => {
        if (driverCardNumber?.length === 16) {
            if (invalidDriverCardNumber(driverCardNumber)) {
                return <FormattedMessage id={'intl-msg:error.driverCard.invalid'} />;
            }
            const alreadyExistingDrivers = driversWithSimilarDriverCards(
                selectedCountry,
                driverCardNumber,
                props.existingDrivers,
                props.currentDriver
            );
            if (alreadyExistingDrivers.length > 0) {
                const existingDriverNamesAndStatus: string = alreadyExistingDrivers
                    .map((driver) => {
                        return existingDriverErrorString(intl, driver);
                    })
                    .join(', ');
                return (
                    <span>
                        <FormattedMessage id={'intl-msg:error.driverCard.alreadyExists'} />
                        {': '}
                        {existingDriverNamesAndStatus}
                    </span>
                );
            }
        }
        return undefined;
    };

    const driverCardHelp = (
        <a
            className={'link white-space-nowrap margin-left-10'}
            href={driverCardHelpLink}
            target={'_blank'}
            rel={'noreferrer'}
            tabIndex={-1}
            style={{ float: 'right' }}
        >
            <span>
                <FormattedMessage id={'intl-msg:createDriver.addDriver.dialog.driverCardInfoLink.label'} />
            </span>
        </a>
    );

    return (
        <div className={'row'}>
            <div className={props.className || 'col-12 col-sm-6 form-group'}>
                <label>
                    <span>
                        <FormattedMessage
                            id={'intl-msg:createDriver.addDriver.dialog.formLabel.euDriverCardIssuingCountry'}
                        />
                        *
                    </span>
                </label>
                <div data-testid="issuingCountryDropdown">
                    <Select
                        value={[selectedCountry]}
                        onChange={handleSelectedCountryChange}
                        options={countryCodeOptions}
                        className="test-className"
                        btnClassName="test-btnClassName"
                        dropdownClassName="test-dropdownClassName"
                    />
                </div>
            </div>
            <div className={props.className || 'col-12 col-sm-6 form-group'}>
                <FormInputGroup
                    fieldError={driverCardNumberErrorMessage()}
                    fieldLabel={
                        <>
                            <span>
                                <FormattedMessage
                                    id={'intl-msg:createDriver.addDriver.dialog.formLabel.euDriverCardNumber'}
                                />
                                *
                            </span>
                            {driverCardHelp}
                        </>
                    }
                    fieldName={'driverCard'}
                    value={driverCardNumber || ''}
                    onChange={handleDriverCardNumberChange}
                    readOnly={false}
                    type={'text'}
                    requiredLength={16}
                    maxLength={16}
                    dataAttribute={'createDriverCardNumberField'}
                />
            </div>
        </div>
    );
};

const sortByTranslation = (intl: IntlShape, countries: DriverCardCountry[]): DriverCardCountry[] => {
    return countries
        .map((v) => ({
            country: v,
            translation: intl.formatMessage({ id: countryPhraseID(v) }),
        }))
        .sort((a, b) => a.translation.localeCompare(b.translation))
        .map((v) => v.country);
};

const generateIdentification = (selectedCountry: DriverCardCountry, driverCardNumber: string | undefined) => {
    if (driverCardNumber) {
        return mapDriverCardCountryToPrefix(selectedCountry) + driverCardNumber;
    } else {
        return null;
    }
};
