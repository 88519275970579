import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'ramda';
import { LIST_MODE_CARDS, PROP_DRIVER_ID } from '../../../../schema';
import { formatUserNameForDisplay, isMobile } from '../../../../utils';
import { DriversTable } from '../../components/DriversTable';
import { DriversTableToolbar } from '../../components/DriversTableToolbar';
import { Loading } from '../../../Loading';
import { NotFound } from '../../../NotFound';
import { Empty } from '../../../Empty';
import { ErrorBlock } from '../../../ErrorBlock';
import LoadMoreButton from '@rio-cloud/rio-uikit/LoadMoreButton';
import { Driver, DriverStatus, Tag, User } from '../../../../../../types';
import { TablePropertiesFromDispatch, TablePropertiesFromState } from '../containers/TableContainer';

const WRAPPER_ID = 'DriverTable';
export const DRIVERS_PER_PAGE = 100;

type TableProperties = TablePropertiesFromState & TablePropertiesFromDispatch;

export const Table = (props: TableProperties) => {
    const {
        fetchDrivers,
        fetchUsers,
        selectedDriverId,
        selectionChange,
        viewTypeChange,
        hasDataFetchError,
        fetchDriversRequested,
        selectedDriver,
        users,
        drivers,
        driverStatuses,
        allTagsInAccount,
        driversListMode,
        searchText,
        sortBy,
        sortDir,
    } = props;
    const [selectedDriverIdsForMultiSelect, setSelectedDriverIdsForMultiSelect] = useState<string[]>([]);
    const [numberOfDriversToDisplay, setNumberOfDriversToDisplay] = useState<number>(DRIVERS_PER_PAGE);
    const [filteredDrivers, setFilteredDrivers] = useState<Driver[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            await fetchDrivers();
            await fetchUsers();
        };
        fetchData().catch((e) => console.warn('Error fetching data', e));
    }, [fetchDrivers, fetchUsers]);

    useEffect(() => {
        if (selectedDriverId) {
            selectionChange(undefined, selectedDriverId);
        }
    }, [selectedDriverId, selectionChange]);

    useEffect(() => {
        if (isMobile()) {
            viewTypeChange(LIST_MODE_CARDS);
        }
    }, [viewTypeChange]);

    useEffect(() => {
        setFilteredDrivers(filterDrivers(drivers, users, searchText, driverStatuses, allTagsInAccount));
    }, [drivers, users, searchText, driverStatuses, allTagsInAccount]);

    useEffect(() => {
        const updatedSelectedDriverIds = selectedDriverIdsForMultiSelect.filter((id) =>
            drivers.map((driver) => driver.driverId).includes(id)
        );
        if (updatedSelectedDriverIds !== selectedDriverIdsForMultiSelect) {
            setSelectedDriverIdsForMultiSelect(updatedSelectedDriverIds);
        }
        // adding selectedDriverIdsForMultiSelect to the deps would create an infinite loop;
        // We need to react to a change of drivers prop to remove corresponding ids from selectedDriverIdsInMultiselect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drivers]);

    const isTargetCheckbox = (event: React.MouseEvent) => {
        const target = event.target as Element;
        const classListValue = target.classList.value;
        return classListValue === 'checkbox-text' || classListValue === 'checkbox';
    };

    const toggleDriverIdSelection = (key: string | null) => {
        if (key) {
            const updatedSelectedDriverIds = selectedDriverIdsForMultiSelect.includes(key)
                ? selectedDriverIdsForMultiSelect.filter((it) => it !== key)
                : selectedDriverIdsForMultiSelect.concat(key);
            setSelectedDriverIdsForMultiSelect(updatedSelectedDriverIds);
        }
    };

    const toggleAllCheckboxes = (enable: boolean) => {
        if (enable) {
            const filteredDriverIds = filteredDrivers.map((driver) => driver.driverId);
            const hiddenSelectedDriverIds = selectedDriverIdsForMultiSelect.filter((driverId) => {
                return !filteredDriverIds.includes(driverId);
            });
            setSelectedDriverIdsForMultiSelect(filteredDriverIds.concat(hiddenSelectedDriverIds));
        } else {
            setSelectedDriverIdsForMultiSelect(
                selectedDriverIdsForMultiSelect.filter(
                    (driverId) => !filteredDrivers.map((filteredDriver) => filteredDriver.driverId).includes(driverId)
                )
            );
        }
    };

    const handleSelectionChange = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const { selectionChange, deselectDriver, fetchDriverRequested, selectedDriver } = props;
        // key should always contain an attribute 'data-key' as it corresponds to a row click event
        // if it is null anyway (=bug) the validation of 'key' in the selectionChange method reports the error to sentry
        const key = event.currentTarget.getAttribute('data-key')!;
        if (isTargetCheckbox(event)) {
            toggleDriverIdSelection(key);
        } else {
            const oldKey = selectedDriver ? selectedDriver.driverId : undefined;
            if (!fetchDriverRequested && key === oldKey) {
                deselectDriver();
            } else if (!fetchDriverRequested && key) {
                selectionChange(oldKey, key);
            }
        }
    };

    const handleSortChange = (event: React.MouseEvent) => {
        const { sortChange } = props;
        const sortBy = event.currentTarget.getAttribute('data-sortby');
        if (sortBy !== null) {
            sortChange(sortBy);
        }
    };

    const handleSearchTextChange = (searchText: string) => {
        const { drivers, users, searchTextChanged, driverStatuses, allTagsInAccount } = props;

        searchTextChanged(searchText);
        const filteredDrivers = filterDrivers(drivers, users, searchText, driverStatuses, allTagsInAccount);
        setFilteredDrivers(filteredDrivers);
    };

    const handleLoadMore = () => {
        setNumberOfDriversToDisplay(numberOfDriversToDisplay + DRIVERS_PER_PAGE);
    };

    const hasData = !isEmpty(drivers);
    const driversToRender = filteredDrivers.slice(0, numberOfDriversToDisplay);
    const hasEmptySearchResult = searchText && isEmpty(driversToRender);

    const getContent = () => {
        if (fetchDriversRequested) {
            return <Loading />;
        } else if (hasDataFetchError) {
            return <ErrorBlock />;
        } else if (hasEmptySearchResult) {
            return <NotFound />;
        } else if ((!hasData && !searchText) || isEmpty(filteredDrivers)) {
            return <Empty />;
        } else if (hasData) {
            return (
                <React.Fragment>
                    <DriversTable
                        drivers={driversToRender}
                        selectedDriverId={selectedDriver && selectedDriver[PROP_DRIVER_ID]}
                        driversListMode={driversListMode}
                        selectedDriverIdsForMultiSelect={selectedDriverIdsForMultiSelect}
                        toggleAllCheckboxes={toggleAllCheckboxes}
                        onSelectionChange={handleSelectionChange}
                        onSortChange={handleSortChange}
                        sortBy={sortBy}
                        sortDir={sortDir}
                        users={users}
                        tenant={props.tenant}
                    />
                    <LoadMoreButton
                        data-testid={'loadMoreButton'}
                        loaded={driversToRender.length}
                        total={filteredDrivers.length}
                        onLoadMore={handleLoadMore}
                        loadMoreMessage={<FormattedMessage id={'intl-msg:drivers.loadMore'} />}
                        noMoreMessage={<FormattedMessage id={'intl-msg:drivers.noMore'} />}
                    />
                </React.Fragment>
            );
        }
        return null;
    };

    return (
        <div data-semantic-id={'drivers-view'} id={WRAPPER_ID}>
            <DriversTableToolbar
                onSearchTextChange={handleSearchTextChange}
                onViewTypeChange={viewTypeChange}
                driversListMode={driversListMode}
                searchText={searchText}
                toggleDriverCreationDialog={props.toggleDriverCreationDialog}
                showDriverCreationDialog={props.showDriverCreationDialog}
            />
            {getContent()}
        </div>
    );
};

export const filterDrivers = (
    drivers: Driver[],
    users: User[],
    searchText: string,
    driverStatuses: DriverStatus[],
    allTagsInAccount: Tag[]
) => {
    return searchText
        .toLocaleLowerCase()
        .split(' ')
        .reduce(
            (prevResult, searchString) =>
                prevResult.filter((driver) => {
                    const user = users.find((it) => it.subject === driver.subject);
                    return (
                        driverStatuses.includes(driver.status) &&
                        matchesSearch(driver, user, searchString, allTagsInAccount)
                    );
                }),
            drivers
        );
};

const matchesSearch = (driver: Driver, user: User | undefined, searchString: string, allTagsInAccount: Tag[]) => {
    return (
        (driver.firstName && driver.firstName.toLowerCase().includes(searchString)) ||
        (driver.lastName && driver.lastName.toLowerCase().includes(searchString)) ||
        (driver.activeCardNumber && driver.activeCardNumber.toLowerCase().includes(searchString)) ||
        (driver.email && driver.email.toLowerCase().includes(searchString)) ||
        (driver.phoneNumber && driver.phoneNumber.toLowerCase().includes(searchString)) ||
        formatUserNameForDisplay(user).toLowerCase().includes(searchString) ||
        (driver.tags &&
            driver.tags.some((tagId) =>
                allTagsInAccount.some((t) => t.id === tagId && t.name.toLowerCase().includes(searchString))
            ))
    );
};
