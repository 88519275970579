import Select, { SelectOption } from '@rio-cloud/rio-uikit/Select';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DriverIdentificationFieldProps, IdentificationTypes } from '../../../../types';
import { existingDriverErrorString } from '../../dialogs/components/CreateDriverDialog';
import {
    DriverLicenseCountry,
    getCountrySpecificIdentification,
    phraseLicenseCountry,
    supportedCountries,
} from '../../dialogs/countrySpecificDriverLicenseCountry';
import { driversWithSameDriverLicense, invalidDriverLicenseNumber } from '../../dialogs/driverLicenseValidation';
import { FormInputGroup } from '../../drivers/sidebar/commonElements/FormInputGroup';

const driverLicenseHelpLink =
    'https://americas.rio.cloud/fileadmin/Region/Americas/Website/Brochures_and_Downloads/Cadastro_de_motoristas-RIO.pdf';

export const CountrySpecificDriverLicenseField = (props: DriverIdentificationFieldProps) => {
    const intl = useIntl();
    const [selectedCountry, setSelectedCountry] = useState<DriverLicenseCountry>('BRAZIL');
    const [driverLicenseNumber, setDriverLicenseNumber] = useState<string | undefined>();

    useEffect(() => {
        setDriverLicenseNumber(undefined);
    }, [props.parentDialogShown]);

    const { onIdentificationChangeCallback, existingDrivers } = props;
    useEffect(() => {
        onIdentificationChangeCallback({
            value: getCountrySpecificIdentification(selectedCountry, driverLicenseNumber!),
            isValid:
                invalidDriverLicenseNumber(driverLicenseNumber) ||
                driversWithSameDriverLicense(selectedCountry, driverLicenseNumber, existingDrivers).length > 0,
            type: IdentificationTypes.COUNTRY_SPECIFIC,
        });
    }, [existingDrivers, selectedCountry, driverLicenseNumber, onIdentificationChangeCallback]);

    const handleSelectedCountryChange = (o: SelectOption) => setSelectedCountry(o.id as DriverLicenseCountry);
    const handleDriverLicenseChange = (e: ChangeEvent<HTMLInputElement>) =>
        setDriverLicenseNumber(e.target.value.toUpperCase());

    const countryOptions: SelectOption[] = Array.from(supportedCountries.keys()).map(
        (c: DriverLicenseCountry): SelectOption => ({
            id: c,
            label: <FormattedMessage id={phraseLicenseCountry(c)} />,
        })
    );

    const driverLicenseNumberErrorMessage = (): ReactElement | undefined => {
        if (driverLicenseNumber?.length === 11) {
            if (invalidDriverLicenseNumber(driverLicenseNumber)) {
                return <FormattedMessage id={'intl-msg:error.driverLicense.invalid'} />;
            }
            const alreadyExistingDrivers = driversWithSameDriverLicense(
                selectedCountry,
                driverLicenseNumber,
                props.existingDrivers
            );
            if (alreadyExistingDrivers.length > 0) {
                const existingDriverNamesAndStatus: string = alreadyExistingDrivers
                    .map((driver) => {
                        return existingDriverErrorString(intl, driver);
                    })
                    .join(', ');
                return (
                    <span>
                        <FormattedMessage id={'intl-msg:error.driverLicense.alreadyExists'} />
                        {': '}
                        {existingDriverNamesAndStatus}
                    </span>
                );
            }
        }
        return undefined;
    };

    const driverLicenseHelp = (
        <a
            className={'link white-space-nowrap margin-left-10'}
            href={driverLicenseHelpLink}
            target={'_blank'}
            rel={'noreferrer'}
            tabIndex={-1}
            style={{ float: 'right' }}
        >
            <span>
                <FormattedMessage id={'intl-msg:createDriver.addDriver.dialog.driverCardInfoLink.label'} />
            </span>
        </a>
    );

    return (
        <div className={'row'}>
            <div className={props.className || 'col-12 col-sm-6 form-group'}>
                <label>
                    <span>
                        <FormattedMessage
                            id={
                                'intl-msg:createDriver.addDriver.dialog.formLabel.countrySpecific.driverLicenseIssuingCountry'
                            }
                        />
                        *
                    </span>
                </label>
                <div data-testid="driverLicenseIssuingCountryDropdown">
                    <Select
                        value={[selectedCountry]}
                        onChange={handleSelectedCountryChange}
                        options={countryOptions}
                        className="test-className"
                        btnClassName="test-btnClassName"
                        dropdownClassName="test-dropdownClassName"
                    />
                </div>
            </div>
            <div className={props.className || 'col-12 col-sm-6 form-group'}>
                <FormInputGroup
                    fieldError={driverLicenseNumberErrorMessage()}
                    fieldLabel={
                        <>
                            <span>
                                <FormattedMessage
                                    id={
                                        'intl-msg:createDriver.addDriver.dialog.formLabel.countrySpecific.driverLicense'
                                    }
                                />
                                *
                            </span>
                            {driverLicenseHelp}
                        </>
                    }
                    fieldName={'driverCard'}
                    value={driverLicenseNumber || ''}
                    onChange={handleDriverLicenseChange}
                    readOnly={false}
                    type={'text'}
                    requiredLength={11}
                    maxLength={11}
                    dataAttribute={'createDriverLicenseField'}
                />
            </div>
        </div>
    );
};
