import * as Sentry from '@sentry/browser';
import { config } from '../../config';

const UNDEFINED_TOKEN = 'CREATE_RIO_FRONTEND_';

if (import.meta.env.PROD) {
    // version and environment are defined in the webpack.define plugin
    const release = config.serviceVersion;
    const environment = config.serviceEnvironment;
    const dsn = config.sentryToken;

    const hasToken = !!dsn && !dsn?.startsWith(UNDEFINED_TOKEN);

    if (hasToken && !config.isCapabilityMonitoring) {
        Sentry.init({
            dsn,
            environment,
            release,
        });
    }
}

export const reportErrorToSentry = (...args: [any, any?]) => {
    if (config.isCapabilityMonitoring) {
        console.error(...args);
    } else {
        Sentry.captureException(...args);
    }
};
