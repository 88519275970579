import { createSlice } from '@reduxjs/toolkit';
import { accessToken } from './accessToken.ts';
import { AccessToken } from '../types';
import { UserProfile } from 'oidc-client-ts';
import { RootState } from '../setup/store';

export interface TokenHandlingState {
    accessToken: AccessToken;
    idToken: UserProfile | null;
}

const initialState: TokenHandlingState = {
    accessToken: accessToken.getAccessToken(),
    idToken: null,
};

const tokenHandlingSlice = createSlice({
    name: 'tokenHandling',
    initialState,
    reducers: {
        accessTokenStored: (state, action) => {
            state.accessToken = action.payload;
        },
        idTokenStored: (state, action) => {
            state.idToken = action.payload;
        },
    },
});

// Actions
export const { accessTokenStored, idTokenStored } = tokenHandlingSlice.actions;

// Selectors
export const getAccessToken = (state: RootState): string =>
    state.tokenHandling?.accessToken ? state.tokenHandling.accessToken : 'NO_ACCESS_TOKEN_AVAILABLE';

export const getIdToken = (state: RootState): UserProfile | null =>
    state.tokenHandling?.idToken ? state.tokenHandling.idToken : null;

export const getAccountId = (state: RootState): string | undefined =>
    state.tokenHandling?.idToken?.account && typeof state.tokenHandling.idToken.account === 'string'
        ? state.tokenHandling.idToken.account
        : undefined;

export default tokenHandlingSlice.reducer;
