import React, { memo } from 'react';
import { connect } from 'react-redux';
import { NavLink, Route, Routes } from 'react-router-dom';
import { FormattedMessage, IntlProvider } from 'react-intl';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';

import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';

import { DEFAULT_LOCALE, extractLanguage } from '../../configuration/lang/lang';
import { DisplayMessages, getLanguageData, getLocale } from '../../configuration/lang/langSlice';
import { DriversContainer } from './drivers/main/containers/DriversContainer';
import IframeResizer from 'iframe-resizer-react';

import { reportErrorToSentry } from '../../configuration/setup/sentry';
import { config } from '../../config';
import { isUserSessionExpired } from '../../configuration/login/loginSlice';
import DefaultRedirect from './router/DefaultRedirect';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import { getSessionWarningAcknowledgedRtk, hideSessionWarning } from './appSlice';
import { AppDispatch, RootState } from '../../configuration/setup/store';
import { messagesEN } from '../../test/testUtils';

const HOME_ROUTE = <a href={`https://home.rio.cloud`}>{''}</a>;

interface DriverAdminProps {
    hideSessionWarning: () => void;
    locale: string;
    sessionWarningAcknowledged: boolean;
    userSessionExpired: boolean;
}

const DriverAdmin = memo((props: DriverAdminProps) => {
    const { hideSessionWarning, locale, sessionWarningAcknowledged, userSessionExpired } = props;

    const userMenu = <DefaultUserMenu environment={import.meta.env.PROD ? 'production' : 'development'} />;
    const notifications = <RioNotifications />;
    const actionBarItems = config.isCapabilityMonitoring ? [] : [notifications, userMenu];
    const menuUrl = config.backend.MENU_SERVICE;
    const menu = config.isCapabilityMonitoring ? undefined : <IframeResizer className="iFrameResizer" src={menuUrl} />;
    const appTitle = <FormattedMessage id={'intl-msg:moduleName'} />;

    const navItems = [
        {
            key: 'assets',
            route: (
                <a href={config.links.ASSET_ADMINISTRATION_WEB}>
                    <FormattedMessage id={'intl-msg:tabTitle.assets'} />
                </a>
            ),
        },
        {
            key: 'drivers',
            route: (
                <NavLink to={'/drivers'}>
                    <FormattedMessage id={'intl-msg:tabTitle.drivers'} />
                </NavLink>
            ),
        },
        {
            key: 'tags',
            route: (
                <a href={config.links.TAG_ADMINISTRATION_WEB}>
                    <FormattedMessage id={'intl-msg:tabTitle.tags'} />
                </a>
            ),
        },
    ];

    const header = (
        <ApplicationLayout.Header>
            <ApplicationHeader
                actionBarItems={actionBarItems}
                appNavigator={menu}
                homeRoute={HOME_ROUTE}
                label={appTitle}
                navItems={navItems}
            />
        </ApplicationLayout.Header>
    );

    return (
        <React.Fragment>
            <SessionExpiredDialog
                locale={locale}
                onClose={hideSessionWarning}
                show={userSessionExpired && !sessionWarningAcknowledged}
            />
            <Routes>
                <Route path={'/drivers'} element={<DriversContainer header={header} />} />
                <Route path={'/drivers/:driverId'} element={<DriversContainer header={header} />} />
                <Route path="*" element={<DefaultRedirect />} />
            </Routes>
        </React.Fragment>
    );
});

const intlErrorHandler = (intlError: any) => {
    reportErrorToSentry(intlError);
    console.error(intlError);
};

interface AppProps {
    lang: string | undefined;
    languageData: DisplayMessages;
    hideSessionWarning: () => void;
    locale: string;
    sessionWarningAcknowledged: boolean;
    userSessionExpired: boolean;
}

export const App = (props: AppProps) => {
    const { languageData, locale } = props;
    if (!languageData) {
        return null;
    }
    return (
        <IntlProvider
            defaultLocale={DEFAULT_LOCALE}
            key={locale}
            locale={locale}
            messages={languageData}
            onError={intlErrorHandler}
        >
            <DriverAdmin {...props} />
        </IntlProvider>
    );
};

export const mapStateToProps = (state: RootState) => ({
    lang: extractLanguage(getLocale(state)),
    languageData: getLanguageData(state) || messagesEN,
    locale: getLocale(state) || 'en-GB',
    preferredLocale: getLocale(state),
    sessionWarningAcknowledged: getSessionWarningAcknowledgedRtk(state),
    userSessionExpired: isUserSessionExpired(state),
});

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    hideSessionWarning: () => {
        // dispatch(hideSessionWarningAction()); // mse - remove
        dispatch(hideSessionWarning());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
