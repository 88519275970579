import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getTenant } from '../../../../../../../configuration/login/loginSlice';
import { NewIdentification, Tag } from '../../../../../../../types';
import { getShowUserCreation } from '../../../../../../Drivers.selectors';
import {
    changeEmail,
    changeFirstName,
    changeLastName,
    changeNewIdentification,
    changePhoneNumber,
    changeStatus,
    getFormErrorsRtk,
    getNewIdentificationValueRtk,
    getPendingIdentificationDeletionsRtk,
    getSelectedDriverRtk,
    getShowIdentificationCreationRtk,
    isFetchDriverInProgressRtk,
    setShowNotificationCreation,
    togglePendingIdentificationDeletion,
} from '../../../sidebarSlice';
import {
    DriverInfoFormPropertiesFromDispatch,
    DriverInfoFormPropertiesFromState,
} from '../../types/driverInfoFormTypes';
import { DriverInfoForm } from '../components/DriverInfoForm';
import { getDriversRtk } from '../../../../../appSlice';
import { setTagsToRemoveThunk } from '../../../../../../crossSliceThunks';

const mapStateToProps = (state: any): DriverInfoFormPropertiesFromState => ({
    fetchDriverInProgress: isFetchDriverInProgressRtk(state),
    formErrors: getFormErrorsRtk(state),
    selectedDriver: getSelectedDriverRtk(state),
    pendingDeletions: getPendingIdentificationDeletionsRtk(state),
    newIdentificationValue: getNewIdentificationValueRtk(state),
    showIdentificationCreation: getShowIdentificationCreationRtk(state),
    showUserCreation: getShowUserCreation(state),
    tenant: getTenant(state),
    existingDrivers: getDriversRtk(state),
});

const mapDispatchToProps = (dispatch: any): DriverInfoFormPropertiesFromDispatch => ({
    changeFirstName: (firstName: string) => dispatch(changeFirstName(firstName)),
    changeLastName: (lastName: string) => dispatch(changeLastName(lastName)),
    changePhoneNumber: (phoneNumber: string) => dispatch(changePhoneNumber(phoneNumber)),
    changeEmail: (email: string) => dispatch(changeEmail(email)),
    changeStatus: () => dispatch(changeStatus()),
    togglePendingDeletion: (identificationId: string) =>
        dispatch(togglePendingIdentificationDeletion(identificationId)),
    changeNewIdentificationValue: (identificationValue: NewIdentification | null) =>
        dispatch(changeNewIdentification(identificationValue)),
    removeTag: (tags: Tag[]) => dispatch(setTagsToRemoveThunk(tags)),
    setShowNotificationCreation: (show: boolean) => dispatch(setShowNotificationCreation(show)),
});

export const DriverInfoFormContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(DriverInfoForm));
