import { Tenant } from '../../../../configuration/types';
import { reportErrorToSentry } from '../../../../configuration/setup/sentry';
import { DriverIdentificationFieldProps } from '../../../../types';
import { CountrySpecificDriverLicenseField } from './CountrySpecificDriverLicenseField';
import { EuTachographIdentificationField } from './EuTachographIdentificationField';

export interface IdentificationFieldRowProps extends DriverIdentificationFieldProps {
    tenant: Tenant | undefined;
}

enum DialogType {
    COUNTRY_SPECIFIC,
    EU_TACHOGRAPH,
    UNKNOWN,
}

const mapTenanantToDialogType = (tenant: Tenant | undefined): DialogType => {
    if (tenant?.startsWith('rio-eu.')) {
        return DialogType.EU_TACHOGRAPH;
    } else if (tenant?.startsWith('rio-brazil.')) {
        return DialogType.COUNTRY_SPECIFIC;
    } else {
        return DialogType.UNKNOWN;
    }
};

export const IdentificationFieldRow = (props: IdentificationFieldRowProps) => {
    const dialogType = mapTenanantToDialogType(props.tenant);

    if (dialogType === DialogType.EU_TACHOGRAPH) {
        return (
            <EuTachographIdentificationField
                className={props.className}
                parentDialogShown={props.parentDialogShown}
                existingDrivers={props.existingDrivers}
                onIdentificationChangeCallback={props.onIdentificationChangeCallback}
                currentDriver={props.currentDriver}
            />
        );
    } else if (dialogType === DialogType.COUNTRY_SPECIFIC) {
        return (
            <CountrySpecificDriverLicenseField
                className={props.className}
                parentDialogShown={props.parentDialogShown}
                existingDrivers={props.existingDrivers}
                onIdentificationChangeCallback={props.onIdentificationChangeCallback}
                currentDriver={props.currentDriver}
            />
        );
    } else {
        reportErrorToSentry(`Unknown tenant: ${props.tenant}`);
        return <div />;
    }
};
